/* eslint-disable no-console */
export const FETCH_TIMEOUT = 200;
export const response = (body = '', init) => () => {
  console.log('BODY ==> ', body);
  return new Response(JSON.stringify(body), {
    headers: { 'Content-Type': 'application/json' },
    ...init,
  });
};

export const responseSuccess = (body, status = 200) => response(body, { status });
export const responseError = (body, status = 400) => response(body, { status });

export const getParamsFromURL = resource => {
  const url = new URL(resource);
  const search = url.search.substring(1);
  const queryParams = JSON.parse(
    `{"${decodeURI(search)
      .replace(/"/g, '\\"')
      .replace(/&/g, '","')
      .replace(/=/g, '":"')}"}`,
  );
  return queryParams;
};

export const getParamsFromBody = init => {
  const { body } = init;

  if (typeof body === 'object') {
    return body;
  }
  return JSON.parse(body);
};
