import { createStore, compose, applyMiddleware } from 'redux';
import { persistCombineReducers, persistStore } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import mergeStates from './state-reconciler';
import { transformExpire } from './transform-expire';
import { middlewares } from '../middlewares';
import * as reducers from '../reducers';

const expirationMilliseconds = 15 * 24 * 60 * 60 * 1000; // 15 days
const expirationKey = 'expirationKey';

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: mergeStates,
  transforms: [transformExpire(expirationMilliseconds, expirationKey)],
};
const persistedReducer = persistCombineReducers(persistConfig, reducers);

// Sets up a Chrome extension for time travel debugging.
// See https://github.com/zalmoxisus/redux-devtools-extension for more information.
const devCompose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Initializes the Redux store with a lazyReducerEnhancer (so that you can
// lazily add reducers after the store has been created) and redux-thunk (so
// that you can dispatch async actions). See the "Redux and state management"
// section of the wiki for more details:
// https://github.com/Polymer/pwa-starter-kit/wiki/4.-Redux-and-state-management
export const store = createStore(persistedReducer, devCompose(applyMiddleware(...middlewares)));

export const persistor = persistStore(store, null);
