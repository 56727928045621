import thunk from 'redux-thunk';
import logger from 'redux-logger-default-css';
import { apiMiddleware } from 'redux-api-middleware';
import { createAnalyticsMiddleware, GoogleAnalytics } from './analytics/gaMiddleware';
import { eventsMap } from './analytics/eventsMap';
import { expiredTokenMiddleware } from './expired-token';
import { sentryMiddleware } from './sentry';

const ga = GoogleAnalytics();
const gaMiddleware = createAnalyticsMiddleware(eventsMap, ga);

const middlewares = [thunk, apiMiddleware, expiredTokenMiddleware, sentryMiddleware, gaMiddleware];

if (window.process.env.DEBUG) {
  middlewares.push(logger);
}

export { middlewares };
