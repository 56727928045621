import * as types from '../actions/types';

const INITIAL_COURIER_STATE = {
  phone: '',
  tos: false,
};

export const courier = (state = INITIAL_COURIER_STATE, action) => {
  switch (action.type) {
    case types.COURIER_SET_PHONE:
    case types.COURIER_SET_ALPHA2CODE:
    case types.SET_TOKEN:
    case types.COURIER_SET_TOS:
    case types.COURIER_SET_PIN:
    case types.COURIER_SET_STATUS:
    case types.COURIER_REQUIRE_VALIDATION:
    case types.COURIER_SET_CARRIER:
      return {
        ...state,
        ...action.payload,
      };
    case types.COURIER_RESET:
      return INITIAL_COURIER_STATE;
    default:
      return {
        ...state,
      };
  }
};
