import * as types from './types';
import { getOffline, getErrorMessage, getLang, isHelpHideForever } from '../selectors';
import { updateHelpVisible } from './ui';

import { initGA } from '../services/analytics';
import { didUserConsent, refreshCookiebotBanner } from '../services/cookiebot';

let snackbarTimer;
let newDeliveryTimer;

const ONE_SECOND = 1000;
const MAX_SECONDS_NEW_DELIVERY = 5 * 60;
const SNACK_BAR_TIMEOUT = 4000;

export const setAppMessage = (message, timeout = SNACK_BAR_TIMEOUT) => dispatch => {
  dispatch({
    type: types.APP_MESSAGE_SET,
    message,
  });
  window.clearTimeout(snackbarTimer);

  if (timeout !== -1) {
    snackbarTimer = window.setTimeout(() => dispatch({ type: types.APP_MESSAGE_RESET }), timeout);
  }
};

export const setAppError = error => dispatch => {
  // If error message is empty we asume it is controlled so we don't display the snackbar
  if (error.message) {
    dispatch(setAppMessage('something_went_wrong'));
  }
  // eslint-disable-next-line no-console
  console.warn(error);
};

export const setUpdateVersion = () => dispatch => {
  dispatch(setAppMessage('click_to_update_last_version', -1));
};

export const updateOffline = offline => (dispatch, getState) => {
  dispatch({
    type: types.OFFLINE_CHANGE,
    offline,
  });

  if (offline !== getOffline(getState())) {
    const message = offline ? 'connection_seems_lost' : 'connected_again';
    dispatch(setAppMessage(message));
  }
};

export const updateVersion = () => async dispatch => {
  dispatch({ type: types.APP_MESSAGE_RESET });
  dispatch({ type: types.UPDATE_VERSION });

  const keys = await caches.keys();

  // remove cache
  keys.forEach(async key => caches.delete(key));

  // unregister sw
  const registrations = await navigator.serviceWorker.getRegistrations();
  registrations.forEach(async registration => registration.unregister());

  // eslint-disable-next-line no-restricted-globals
  location.reload();
};

export const updateActiveScanning = activeScanning => ({
  type: types.ACTIVE_SCANNING,
  payload: { activeScanning },
});

export const resetErrorMessage = () => (dispatch, getState) => {
  const state = getState();
  if (getErrorMessage(state)) {
    dispatch({ type: types.ERROR_MESSAGE_RESET });
  }
};

export const setErrorMessage = errorMessage => ({
  type: types.ERROR_MESSAGE_SET,
  errorMessage,
});

const updateLayout = ({ header, hasFooter, flavour, flow, progress }) => (dispatch, getState) => {
  const state = getState();
  const headerLayout = { ...header };
  if (header && header.title && typeof header.title === 'function') {
    headerLayout.title = header.title(state);
  }

  return dispatch({
    type: types.LAYOUT_UPDATE,
    payload: {
      header: headerLayout,
      hasFooter,
      flavour,
      flow,
      progress,
    },
  });
};

const setNewDeliveryProcess = () => ({
  type: types.SET_NEW_DELIVERY_PROCESS,
});

const startNewDeliveryTimer = () => dispatch => {
  let secondsSinceLastDelivery = 0;
  newDeliveryTimer = setInterval(() => {
    secondsSinceLastDelivery += 1;
    if (secondsSinceLastDelivery >= MAX_SECONDS_NEW_DELIVERY) {
      dispatch(setNewDeliveryProcess());
      clearInterval(newDeliveryTimer);
    }
  }, ONE_SECOND);
};

const showInstructions = () => (dispatch, getState) => {
  if (!isHelpHideForever(getState())) {
    dispatch(updateHelpVisible(true));
  }
};

export const updateRouter = ({ route, params, query, data }) => dispatch => {
  dispatch(updateActiveScanning(''));

  if (newDeliveryTimer) {
    clearInterval(newDeliveryTimer);
  }

  switch (route) {
    case 'location':
      dispatch(updateActiveScanning('QR'));
      break;
    case 'tracking':
      dispatch(updateActiveScanning('BARCODE'));
      break;
    case 'code':
      dispatch(showInstructions());
      break;
    case 'success':
      dispatch(startNewDeliveryTimer());
      break;
    default:
      break;
  }
  dispatch({
    type: types.ROUTER_UPDATE,
    payload: { route, params, query },
  });
  dispatch(updateLayout(data));
};

export const setCookies = () => dispatch => {
  if (didUserConsent('statistics')) {
    initGA();
    dispatch({ type: types.COOKIES_LOADED });
  }
};

export const setLang = lang => (dispatch, getState) => {
  const state = getState();
  const currentLang = getLang(state);

  if (currentLang && currentLang !== lang) {
    refreshCookiebotBanner(lang);
  }

  dispatch({ type: types.SET_LANG, lang });
};

export const setAppVersion = appVersion => dispatch => {
  dispatch({ type: types.SET_APP_VERSION, payload: { appVersion } });
};
