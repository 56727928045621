import CookiebotBanner from '@citibox/cookiebot-banner';
import { env } from '../env';

const { COOKIEBOT_ID } = env;

export const initCookiebotBanner = lang => {
  CookiebotBanner.cbid = COOKIEBOT_ID;
  CookiebotBanner.lang = lang;
  CookiebotBanner.setStyles();
  CookiebotBanner.load();
};

export const refreshCookiebotBanner = lang => {
  CookiebotBanner.lang = lang;
  CookiebotBanner.refresh();
};

export const insertCookieDeclaration = (lang, targetNode) => {
  if (typeof window.Cookiebot === 'undefined') {
    initCookiebotBanner(lang);
  }

  CookiebotBanner.loadDeclarationContent(targetNode);
};

export const refreshCookieDeclaration = targetNode => {
  CookiebotBanner.refreshDeclarationContent(targetNode);
};

export const didUserConsent = (consentType = 'statistics') =>
  window.Cookiebot && window.Cookiebot.consent && window.Cookiebot.consent[consentType];
