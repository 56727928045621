import * as api from '../api';
import * as types from '../types';
import { nextStep, loginAndProfile, startLoading, stopLoading } from './flow';
import { setErrorMessage, setAppError } from '../app';
import { setValidation, setStatus } from '../courier';
import { updateIsSmsSent, updateIsLoadingSms, updateIsCodeRequestDisabled } from '../ui';
import { getPhone, getPin, getLang } from '../../selectors';

const SMS_SENT_TIMEOUT = 4000;

const userGuestFlow = () => async dispatch => {
  await dispatch(loginAndProfile());
  return dispatch(nextStep('guest'));
};

const userRegisteredFlow = () => async dispatch => dispatch(nextStep('password'));
const userRegisteredCourierFlow = () => dispatch => dispatch(nextStep('regularDelivery'));

const userUnregisteredFlow = () => async (dispatch, getState) => {
  const state = getState();
  const phone = getPhone(state);
  const language = (getLang(state) || 'es').toUpperCase();

  await dispatch(updateIsCodeRequestDisabled(false));
  await dispatch(updateIsSmsSent(false));
  const apiResponse = await dispatch(api.sendSMS({ phone, language }));
  if (apiResponse.error) {
    const { response: { code } = {} } = apiResponse.payload;
    if (code === 'invalid_phone') {
      return dispatch(setErrorMessage('invalid_phone'));
    }
    if (code === 'codes_request_exceeded') {
      return dispatch(setErrorMessage('maximum_number_of_codes'));
    }
    throw new Error(apiResponse.error);
  }
  return dispatch(nextStep('sms'));
};

const submitCourierPhoneError = apiResponse => async (dispatch, getState) => {
  const { response: { code, detail } = {} } = apiResponse.payload;
  const courierPhone = getPhone(getState());

  switch (code) {
    case 'invalid_phone':
      return dispatch(setErrorMessage('invalid_phone'));
    case 'invalid_data':
      if (detail && detail.phone && courierPhone.indexOf('+') === -1) {
        return dispatch(setErrorMessage('missing_prefix'));
      }
      return dispatch(setErrorMessage('invalid_introduced_data'));
    case 'invalid_user_type':
      return dispatch(setErrorMessage('invalid_user_type'));
    case 'unregistered_user':
      return dispatch(userUnregisteredFlow());
    default:
      await dispatch(setErrorMessage('something_went_wrong_try_again'));
      throw new Error(apiResponse.payload);
  }
};

const submitCourierPhoneSuccess = apiResponse => async dispatch => {
  const { status } = apiResponse.payload;
  await dispatch(setStatus(status));
  switch (status) {
    case 'guest':
      return dispatch(userGuestFlow());
    case 'registered':
      return dispatch(userRegisteredFlow());
    case 'courier':
      return dispatch(userRegisteredCourierFlow());
    default:
      throw new Error('submitCourierPhone', status);
  }
};

export const submitCourierPhone = () => async (dispatch, getState) => {
  const loadingProcess = 'FLOW_COURIER_NEXT';
  dispatch(
    startLoading({
      action: loadingProcess,
    }),
  );
  try {
    const phone = getPhone(getState());
    const apiResponse = await dispatch(api.getUserStatus({ phone }));
    if (apiResponse.error) {
      await dispatch(submitCourierPhoneError(apiResponse));
    } else {
      await dispatch(submitCourierPhoneSuccess(apiResponse));
    }
  } catch (err) {
    await dispatch(setAppError(err));
  }
  return dispatch(stopLoading(loadingProcess));
};

const submitVerifyPhoneError = apiResponse => async dispatch => {
  const { response: { code, detail: { phone } = [] } = {} } = apiResponse.payload;
  if (code === 'invalid_data') {
    if (phone && phone[0] && phone[0].includes('Not a valid phone number')) {
      dispatch(setErrorMessage('invalid_phone'));
    } else {
      dispatch(setErrorMessage('invalid_code'));
    }
  } else {
    await dispatch(setErrorMessage('something_went_wrong_try_again'));
    throw new Error(apiResponse.error);
  }
};

const submitVerifyPhoneSuccess = () => dispatch => {
  dispatch(setValidation(''));
  return dispatch(nextStep('next'));
};

export const submitVerifyPhone = () => async (dispatch, getState) => {
  const loadingProcess = 'FLOW_COURIER_VERIFY_NEXT';
  dispatch(
    startLoading({
      action: loadingProcess,
    }),
  );
  try {
    const state = getState();
    const phone = getPhone(state);
    const pin = getPin(state);
    const apiResponse = await dispatch(api.verifyPhone({ phone, introduced_pin: pin }));
    if (apiResponse.error) {
      await dispatch(submitVerifyPhoneError(apiResponse));
    } else if (apiResponse.payload.success) {
      await dispatch(submitVerifyPhoneSuccess());
    } else {
      await dispatch(setErrorMessage('invalid_introduced_data'));
    }
  } catch (err) {
    await dispatch(setAppError(err));
  }
  return dispatch(stopLoading(loadingProcess));
};

export const submitVerifyPassword = password => async dispatch => {
  const loadingProcess = 'FLOW_COURIER_VERIFY_PASSWORD';
  dispatch(
    startLoading({
      action: loadingProcess,
    }),
  );
  try {
    await dispatch(loginAndProfile(password));
    await dispatch(nextStep('next'));
    await dispatch(setValidation(''));
  } catch (err) {
    /* Continue regardless API error to stop loading process */
  }
  return dispatch(stopLoading(loadingProcess));
};

export const submitCountry = () => dispatch => dispatch(nextStep('next'));

const getLegalLocation = (lang, path) => `https://citibox.com/${lang}/${path}/courier`;

export const readTerms = () => (dispatch, getState) => {
  dispatch({
    type: types.READ_TERMS,
  });

  const lang = getLang(getState());
  window.location = getLegalLocation(lang, 'terms');
};

export const readPrivacy = () => (dispatch, getState) => {
  dispatch({
    type: types.READ_PRIVACY,
  });

  const lang = getLang(getState());
  window.location = getLegalLocation(lang, 'privacy');
};

export const requestSmsValidation = () => async (dispatch, getState) => {
  const state = getState();
  const phone = getPhone(state);
  const language = (getLang(state) || 'es').toUpperCase();

  dispatch({
    type: types.RETRY_CODE,
  });
  dispatch(updateIsLoadingSms(true));
  const apiResponse = await dispatch(api.sendSMS({ phone, language }));

  if (apiResponse.error) {
    const {
      payload: { response: { code } = {} },
    } = apiResponse;

    switch (code) {
      case 'codes_request_exceeded':
        dispatch(setErrorMessage('maximum_number_of_codes'));
        break;
      case 'invalid_data':
        dispatch(setErrorMessage('invalid_code'));
        break;

      default:
        throw new Error(apiResponse.error);
    }
    dispatch(updateIsCodeRequestDisabled(true));
  } else {
    dispatch(updateIsSmsSent(true));
    setTimeout(() => {
      dispatch(updateIsSmsSent(false));
    }, SMS_SENT_TIMEOUT);
  }
  dispatch(updateIsLoadingSms(false));
};
