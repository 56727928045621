export const APP_MESSAGE_SET = 'APP_MESSAGE_SET';
export const APP_MESSAGE_RESET = 'APP_MESSAGE_RESET';
export const ACTIVE_SCANNING = 'ACTIVE_SCANNING';
export const COOKIES_LOADED = 'COOKIES_LOADED';
export const ERROR_MESSAGE_SET = 'ERROR_MESSAGE_SET';
export const ERROR_MESSAGE_RESET = 'ERROR_MESSAGE_RESET';
export const LAYOUT_UPDATE = 'LAYOUT_UPDATE';
export const OFFLINE_CHANGE = 'OFFLINE_CHANGE';
export const READ_COOKIES_POLICY = 'READ_COOKIES_POLICY';
export const ROUTER_UPDATE = 'ROUTER_UPDATE';
export const SET_LANG = 'SET_LANG';
export const SET_NEW_DELIVERY_PROCESS = 'SET_NEW_DELIVERY_PROCESS';
export const SET_APP_VERSION = 'SET_APP_VERSION';
export const UPDATE_VERSION = 'UPDATE_VERSION';
