import * as api from '../api';
import * as types from '../types';
import { nextStep, startLoading, stopLoading } from './flow';
import { setAppMessage, setAppError } from '../app';
import { resetDelivery } from '../delivery';
import {
  updateIsAutofixAcceptLoading,
  updateIsAutofixPrimaryLoading,
  updateIsAutofixSecondaryLoading,
  updateAutofixVisible,
} from '../ui';
import { getBox, deliveryIdSelector, getStatus } from '../../selectors';

const STATUS_REGISTERED = 'registered';

export const openAutofix = () => ({
  type: types.OPEN_AUTOFIX,
});

export const closeAutofix = () => ({
  type: types.CLOSE_AUTOFIX,
});

export const selectAutofixOption = option => ({
  type: types.SELECT_AUTOFIX_OPTION,
  payload: { option },
});

export const finishDelivery = () => async (dispatch, getState) => {
  const loadingProcess = 'FLOW_FINISH';
  dispatch(
    startLoading({
      action: loadingProcess,
    }),
  );
  const status = getStatus(getState());
  if (status === STATUS_REGISTERED) {
    await dispatch(setAppMessage('success_delivery'));
    await dispatch(nextStep('carrier'));
    await dispatch(resetDelivery());
  } else {
    await dispatch(nextStep('success'));
  }
  return dispatch(stopLoading(loadingProcess));
};

export const retryDelivery = (reason, codeUsed = false) => async (dispatch, getState) => {
  const currentBox = getBox(getState());
  const loadingProcess = 'FLOW_DELIVERY_RETRY';
  dispatch(
    startLoading({
      action: loadingProcess,
      extras: {
        reason,
        codeUsed,
      },
    }),
  );
  if (reason === 'box_size' && codeUsed) {
    dispatch(updateIsAutofixPrimaryLoading(true));
  }
  if (reason === 'box_size' && !codeUsed) {
    dispatch(updateIsAutofixSecondaryLoading(true));
  }
  if (reason === 'wrong_code' || reason === 'reopen') {
    dispatch(updateIsAutofixAcceptLoading(true));
  }
  const state = getState();
  const transactionId = deliveryIdSelector(state);
  const apiResponse = await dispatch(api.retryDelivery({ transactionId, reason, codeUsed }));

  if (apiResponse.error) {
    const { payload } = apiResponse;
    const { response: { code } = {} } = payload;
    switch (code) {
      case 'box_not_available':
        await dispatch(nextStep('full'));
        break;
      case 'invalid_lock_type':
        await dispatch(nextStep('regularDelivery'));
        break;
      case 'codes_request_exceeded':
        await dispatch(setAppError(new Error('Too many code requests')));
        break;
      case 'parcel_not_found':
        await dispatch(setAppError(new Error('Parcel not found')));
        break;
      default:
        await dispatch(setAppError(new Error(`Unexpected error retrying delivery, code: ${code}`)));
        break;
    }
  } else {
    const deliveryId = Object.keys(apiResponse.payload.entities.deliveries)[0];
    // eslint-disable-next-line camelcase
    const { box_number } = apiResponse.payload.entities.deliveries[deliveryId].attributes;

    // eslint-disable-next-line camelcase
    if (currentBox !== box_number) {
      await dispatch(setAppMessage('new_box'));
    } else {
      await dispatch(setAppMessage('new_key'));
    }
  }
  dispatch(updateIsAutofixPrimaryLoading(false));
  dispatch(updateIsAutofixSecondaryLoading(false));
  dispatch(updateIsAutofixAcceptLoading(false));
  dispatch(updateAutofixVisible(false));
  return dispatch(stopLoading(loadingProcess));
};

export const cancelDelivery = () => async (dispatch, getState) => {
  const loadingProcess = 'DELETE_DELIVERY_REQUEST_RSAA';
  dispatch(
    startLoading({
      action: loadingProcess,
    }),
  );
  const state = getState();
  const transactionId = deliveryIdSelector(state);
  if (transactionId) {
    const apiResponse = await dispatch(api.deleteDelivery({ transactionId }));
    if (apiResponse.error) {
      const { payload } = apiResponse;
      const { response: { code } = {} } = payload;
      switch (code) {
        case 'parcel_not_available':
        case 'parcel_not_found':
        case 'user_not_exists':
        case 'invalid_lock_type':
        case 'invalid_locker_status':
          dispatch(
            setAppError(`Something happened canceling the delivery: ${apiResponse.payload}`),
          );
          break;

        default:
          dispatch(setAppError(`Unknown error canceling the delivery: ${apiResponse.payload}`));
      }
    } else {
      dispatch(nextStep('cancelDelivery'));
      await dispatch(resetDelivery());
    }
  }
  return dispatch(stopLoading(loadingProcess));
};

export const callCustomerService = supportPhone => async (dispatch, getState) => {
  const transactionId = deliveryIdSelector(getState());
  dispatch({
    type: types.CALL_SUPPORT,
  });
  window.open(`tel:${supportPhone}`, '_blank');

  if (transactionId) {
    dispatch(cancelDelivery());
  }
};

export const cantDeliver = () => dispatch => dispatch(cancelDelivery());
