const stabilizeUiState = uiState => {
  const stableUiState = uiState;
  Object.keys(stableUiState).forEach(property => {
    if (property.toLowerCase().includes('loading')) {
      stableUiState[property] = false;
    }
  });
  return stableUiState;
};

const stabilizeAppState = appState => {
  const stableAppState = appState;
  Object.keys(stableAppState).forEach(property => {
    if (property.toLowerCase().includes('message')) {
      stableAppState[property] = '';
    }
  });
  return stableAppState;
};

const stabilizeDeliveryState = deliveryState => {
  const stableDeliveryState = deliveryState;
  delete stableDeliveryState.trackingImage;
  return stableDeliveryState;
};

export default function mergeStates(inboundState, originalState, reducedState) {
  const newState = { ...reducedState };
  if (inboundState && typeof inboundState === 'object') {
    Object.keys(inboundState).forEach(key => {
      switch (key) {
        case '_persist':
          return;
        case 'app':
          newState[key] = {
            ...newState[key],
            ...stabilizeAppState(inboundState[key]),
          };
          break;
        case 'ui':
          newState[key] = {
            ...newState[key],
            ...stabilizeUiState(inboundState[key]),
          };
          break;
        case 'delivery':
          newState[key] = {
            ...newState[key],
            ...stabilizeDeliveryState(inboundState[key]),
          };
          break;
        default:
          newState[key] = {
            ...newState[key],
            ...inboundState[key],
          };
          break;
      }
    });
  }
  return newState;
}
