import { getCourierId, getRoleId } from '../../selectors';

export const stateTransformer = state => {
  const transformedState = {
    ...state,
    courier: {
      ...state.courier,
      courierId: getCourierId(state),
      roleId: getRoleId(state),
      token: null,
      pin: null,
    },
    pagination: null,
    _persist: null,
  };

  return transformedState;
};
