export const HEADER_CTA = 'HEADER_CTA';
export const HEADER_NAV_BUTTON = 'HEADER_NAV_BUTTON';
export const START_DELIVERY = 'START_DELIVERY';
export const LEARN_MORE = 'LEARN_MORE';
export const FLOW_GUEST_PROFILE = 'FLOW_GUEST_PROFILE';
export const FLOW_USER_PROFILE = 'FLOW_USER_PROFILE';
export const READ_TERMS = 'READ_TERMS';
export const READ_PRIVACY = 'READ_PRIVACY';
export const RETRY_CODE = 'RETRY_CODE';
export const SEARCH_CARRIER = 'SEARCH_CARRIER';
export const CREATE_CARRIER = 'CREATE_CARRIER';
export const SCAN_QR = 'SCAN_QR';
export const MANUAL_INPUT_QR = 'MANUAL_INPUT_QR';
export const AUTO_QR = 'AUTO_QR';
export const DENY_CAMERA_QR = 'DENY_CAMERA_QR';
export const MANUAL_ENTRY = 'MANUAL_ENTRY';
export const CAMERA_ENTRY = 'CAMERA_ENTRY';
export const TAKE_PICTURE = 'TAKE_PICTURE';
export const CONSUMER_SELECTED = 'CONSUMER_SELECTED';
export const ADD_NEW_USER = 'ADD_NEW_USER';
export const NEW_CONSUMER_NEXT = 'NEW_CONSUMER_NEXT';
export const PROVISIONAL_CONSUMER_NOT_ALLOWED = 'PROVISIONAL_CONSUMER_NOT_ALLOWED';
export const CONFIRM_CONSUMER_MODAL = 'CONFIRM_CONSUMER_MODAL';
export const CANCEL_CONSUMER_MODAL = 'CANCEL_CONSUMER_MODAL';
export const CLOSE_CONSUMER_MODAL = 'CLOSE_CONSUMER_MODAL';
export const CHANGE_LOCKER = 'CHANGE_LOCKER';
export const OPEN_AUTOFIX = 'OPEN_AUTOFIX';
export const CLOSE_AUTOFIX = 'CLOSE_AUTOFIX';
export const SELECT_AUTOFIX_OPTION = 'SELECT_AUTOFIX_OPTION';
export const CALL_SUPPORT = 'CALL_SUPPORT';
export const CONFIRM_NEW_DELIVERY = 'CONFIRM_NEW_DELIVERY';
export const CLOSE_SUCCESS_MODAL = 'CLOSE_SUCCESS_MODAL';
export const MAKE_ANOTHER_DELIVERY = 'MAKE_ANOTHER_DELIVERY';
export const DOWNLOAD_APP = 'DOWNLOAD_APP';
export const CONSUMER_WITHOUT_PHONE = 'CONSUMER_WITHOUT_PHONE';
