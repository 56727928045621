import { denormalize } from 'normalizr';
import { createSelector } from 'reselect';

import { schemas } from '../api';
import { usersSelector, carriersSelector } from './entities';
import { paginatedCarriersSelector } from './pagination';

const getCourier = state => state.courier;
export const getToken = state => getCourier(state).token;
export const getPhone = state => getCourier(state).phone;
export const getAlpha2Code = state => getCourier(state).alpha2Code;
export const getStatus = state => getCourier(state).status;
export const getValidation = state => getCourier(state).validation;
export const getPin = state => getCourier(state).pin;
export const getTos = state => getCourier(state).tos;
export const getSelectedCarrierId = state => getCourier(state).carrierId;

/* eslint-disable camelcase */
const courierEntitySelector = createSelector(
  [getPhone, usersSelector, carriersSelector],
  (courierPhone, users, carriers) => {
    const courier = denormalize(courierPhone, schemas.USER, { users, carriers });
    if (!courier) {
      return {};
    }

    const { carrier } = courier;
    if (carrier) {
      const { allow_provisional_users, ...rest } = carrier;
      const newCarrier = {
        ...rest,
        allowProvisionalUsers: allow_provisional_users !== false,
      };

      return { ...courier, carrier: newCarrier };
    }
    return courier;
  },
);

const carrierEntitySelector = createSelector(
  [getSelectedCarrierId, carriersSelector],
  (carrierId, carriers) => {
    const carrier = denormalize(carrierId, schemas.CARRIER, { carriers });
    if (!carrier) {
      return {};
    }

    const { allow_provisional_users, ...rest } = carrier;
    const newCarrier = {
      ...rest,
      allowProvisionalUsers: allow_provisional_users !== false,
    };
    return newCarrier;
  },
);

export const getCourierId = state =>
  courierEntitySelector(state) ? courierEntitySelector(state).id : '';
export const getRoleId = state =>
  courierEntitySelector(state) ? courierEntitySelector(state).role : '';
export const getLocationId = state =>
  courierEntitySelector(state) ? courierEntitySelector(state).location : '';

const getCarrier = state => {
  let { carrier } = courierEntitySelector(state);

  if (!carrier) {
    carrier = carrierEntitySelector(state);
  }
  return carrier;
};
export const getCarrierName = state => (getCarrier(state) ? getCarrier(state).name : '');
export const getCarrierId = state => (getCarrier(state) ? getCarrier(state).id : '');
export const getCarrierAllowProvisionalUsers = state =>
  getCarrier(state) ? getCarrier(state).allowProvisionalUsers : '';

export const getCarriers = createSelector(
  [paginatedCarriersSelector, carriersSelector],
  (carriers, carrierEntities) =>
    denormalize(carriers.ids, [schemas.CARRIER], { carriers: carrierEntities }).map(carrier => {
      const { images = [] } = carrier;
      const logo = images.find(image => image.type === 'logo') || {};
      const response = {
        id: carrier.id,
        name: carrier.name,
      };
      if (logo) {
        response.logo = logo.image || '';
        response.color = logo.background_color || '';
        if (response.color && response.color.charAt(0) !== '#') {
          response.color = `#${response.color}`;
        }
      }
      return response;
    }),
);
