import { pushState, resetUser, setAppMessage } from '../actions';

export const expiredTokenMiddleware = ({ dispatch }) => next => action => {
  const { response: { code } = {} } = action.payload || {};
  if (code === 'not_authenticated') {
    dispatch(setAppMessage('session_expired'));
    dispatch(resetUser());
    pushState('/courier');
  }
  return next(action);
};
