import * as types from './types';
import { resetErrorMessage } from './app';

export const setToken = token => ({ type: types.SET_TOKEN, payload: { token } });

export const setCourierPhone = phone => dispatch => {
  dispatch(resetErrorMessage());
  return dispatch({
    type: types.COURIER_SET_PHONE,
    payload: { phone },
  });
};

export const setAlpha2Code = alpha2Code => dispatch =>
  dispatch({
    type: types.COURIER_SET_ALPHA2CODE,
    payload: { alpha2Code },
  });

export const setTos = tos => dispatch =>
  dispatch({
    type: types.COURIER_SET_TOS,
    payload: { tos },
  });

export const setPin = pin => dispatch => {
  dispatch(resetErrorMessage());
  return dispatch({
    type: types.COURIER_SET_PIN,
    payload: { pin },
  });
};

export const setStatus = status => dispatch =>
  dispatch({
    type: types.COURIER_SET_STATUS,
    payload: { status },
  });

export const setCarrier = carrier => dispatch =>
  dispatch({
    type: types.COURIER_SET_CARRIER,
    payload: { carrierId: carrier.id },
  });

export const setValidation = validation => dispatch =>
  dispatch({
    type: types.COURIER_REQUIRE_VALIDATION,
    payload: { validation },
  });

export const createCarrier = name => ({
  type: types.CARRIERS_CREATE,
  name,
});

export const resetUser = () => ({
  type: types.COURIER_RESET,
});
