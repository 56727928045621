import { trackPageView, trackEvent, trackException, setConfig } from './gaMiddleware';
import * as types from '../../actions/types';
import { getRoute } from '../../selectors';

export const eventsMap = {
  // Global events
  [types.ROUTER_UPDATE]: trackPageView(action => ({
    page: action.payload.route,
    title: document.title,
    location: window.location.pathname,
  })),

  [types.ERROR_MESSAGE_SET]: trackException(action => ({
    exDescription: action.errorMessage,
    exFatal: false,
  })),

  [types.LOADING_START]: trackEvent(action => ({
    hitType: 'event',
    category: action.payload.route,
    action: 'Confirm',
    label: action.payload.label,
  })),

  [types.LOADING_STOP]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: action.payload.route,
    action: 'Confirm-end',
    label: getRoute(prevState),
    value: action.payload.diff,
  })),

  [types.SET_LANG]: setConfig(action => ({
    dimension6: action.lang,
  })),

  [types.SET_APP_VERSION]: setConfig(action => ({
    dimension7: action.payload.appVersion,
  })),

  [types.UPDATE_VERSION]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Update version',
    label: 'Actualizar a última version',
  })),

  // Header
  [types.HEADER_CTA]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: action.payload.cta,
  })),

  [types.HEADER_NAV_BUTTON]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'back',
  })),

  // Home
  [types.START_DELIVERY]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Confirm',
    label: 'Realizar nueva entrega',
  })),

  [types.LEARN_MORE]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'info',
    label: 'Saber más 👇',
  })),

  // Courier
  [types.READ_PRIVACY]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'privacy',
  })),

  [types.READ_TERMS]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'terms',
  })),

  [types.COURIER_SET_TOS]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: action.payload.tos ? 'check' : 'uncheck',
  })),

  [types.RETRY_CODE]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Retry Code',
    label: 'No he recibido el mensaje',
  })),

  [types.FLOW_GUEST_PROFILE]: setConfig(action => ({
    dimension1: action.payload.carrierId.toString(),
    dimension2: action.payload.courierId.toString(),
    dimension5: action.payload.roleId.toString(),
  })),

  [types.FLOW_USER_PROFILE]: setConfig(action => ({
    dimension2: action.payload.courierId.toString(),
    dimension5: action.payload.roleId.toString(),
  })),

  [types.DELIVERY_SET_LOCATION_ID]: setConfig(action => ({
    dimension3: action.payload.locationId,
  })),

  [types.DELIVERY_SET_ID]: setConfig(action => ({
    dimension4: action.payload.deliveryId,
  })),

  // Carrier
  [types.COURIER_SET_CARRIER]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Select Carrier',
    label: action.payload.carrierId,
  })),

  [types.SEARCH_CARRIER]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Search Carrier',
  })),

  [types.CREATE_CARRIER]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Create Carrier',
  })),

  // Location
  [types.SCAN_QR]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Scan QR',
    label: action.payload,
  })),

  [types.MANUAL_INPUT_QR]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Manual input QR',
    label: action.payload,
  })),

  [types.AUTO_QR]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Auto QR',
    label: action.payload,
  })),

  [types.DENY_CAMERA_QR]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Deny camera QR',
  })),

  [types.MANUAL_ENTRY]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Manual entry QR',
    label: '¿No se escanéa el código? Introducir a mano',
  })),

  [types.CAMERA_ENTRY]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Camera entry QR',
    label: 'Volver a la cámara',
  })),

  // Tracking code
  [types.DELIVERY_SET_TRACKING]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Scan Tracking',
    label: action.payload.tracking,
  })),

  [types.TAKE_PICTURE]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Take picture',
    label: '¿No puedes leer el código? Tomar una fotografía',
  })),

  // Consumer
  [types.CONSUMER_SELECTED]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Select Consumer',
    label: action.payload.consumerPhone,
  })),

  [types.NEW_CONSUMER_NEXT]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Confirm',
  })),

  [types.CONSUMER_WITHOUT_PHONE]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Consumer without phone',
  })),

  [types.ADD_NEW_USER]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Add new user',
    label: action.payload.source,
  })),

  [types.CONFIRM_CONSUMER_MODAL]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Confirm Consumer Modal',
  })),

  [types.CANCEL_CONSUMER_MODAL]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Cancel Consumer Modal',
  })),

  [types.CLOSE_CONSUMER_MODAL]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Close Consumer Modal',
  })),

  // Locker
  [types.CHANGE_LOCKER]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Change locker',
  })),

  // Code
  [types.IS_HELP_HIDE_FOREVER]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Hide instructions forever',
    label: action.payload.isHelpHideForever,
  })),

  [types.HELP_VISIBLE_UPDATE]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'See instructions',
    label: action.payload.helpVisible ? 'open' : 'close',
  })),

  [types.OPEN_AUTOFIX]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Fix',
  })),

  [types.CLOSE_AUTOFIX]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Close autofix',
  })),

  [types.SELECT_AUTOFIX_OPTION]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Select autofix option',
    label: action.payload.option,
  })),

  [types.CALL_SUPPORT]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Call support',
    label: 'Llamar a soporte',
  })),

  [types.IS_CANCEL_DELIVERY_VISIBLE_UPDATE]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Cancel delivery dialog',
    label: action.payload.isCancelDeliveryVisible ? `open from ${action.payload.origin}` : 'close',
  })),

  [types.IS_CALL_SUPPORT_VISIBLE_UPDATE]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Call support dialog',
    label: action.payload.isCallSupportVisible ? 'open' : 'close',
  })),

  // Delivery
  [types.DELETE_DELIVERY_REQUEST]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Delete delivery',
  })),

  // Success
  [types.CONFIRM_NEW_DELIVERY]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Start new delivery',
    label: action.payload.nextLocation,
  })),

  [types.CLOSE_SUCCESS_MODAL]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Close Success Modal',
  })),

  [types.MAKE_ANOTHER_DELIVERY]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Confirm',
    label: 'Nueva entrega',
  })),

  [types.DOWNLOAD_APP]: trackEvent((action, prevState) => ({
    hitType: 'event',
    category: getRoute(prevState),
    action: 'Download app',
    label: 'Descargar la app',
  })),
};
