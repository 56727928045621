import * as types from './types';

export const updateIsScanSuccessful = isScanSuccessful => ({
  type: types.IS_SCAN_SUCCESSFUL_UPDATE,
  payload: { isScanSuccessful },
});

export const updateIsScanInvalid = isScanInvalid => ({
  type: types.IS_SCAN_ERRONEOUS_UPDATE,
  payload: { isScanInvalid },
});

export const updateIsLoadingSms = isLoadingSms => ({
  type: types.IS_LOADING_SMS_UPDATE,
  payload: { isLoadingSms },
});

export const updateIsSmsSent = isSmsSent => ({
  type: types.IS_SMS_SENT_UPDATE,
  payload: { isSmsSent },
});

export const updateIsCodeRequestDisabled = isCodeRequestDisabled => ({
  type: types.IS_CODE_REQUEST_DISABLED_UPDATE,
  payload: { isCodeRequestDisabled },
});

export const updateIsAutofixAcceptLoading = isAutofixAcceptLoading => ({
  type: types.IS_AUTOFIX_ACCEPT_LOADING_UPDATE,
  payload: { isAutofixAcceptLoading },
});

export const updateIsAutofixPrimaryLoading = isAutofixPrimaryLoading => ({
  type: types.IS_AUTOFIX_PRIMARY_LOADING_UPDATE,
  payload: { isAutofixPrimaryLoading },
});

export const updateIsAutofixSecondaryLoading = isAutofixSecondaryLoading => ({
  type: types.IS_AUTOFIX_SECONDARY_LOADING_UPDATE,
  payload: { isAutofixSecondaryLoading },
});

export const updateAutofixVisible = autofixVisible => ({
  type: types.AUTOFIX_VISIBLE_UPDATE,
  payload: { autofixVisible },
});

export const updateHelpVisible = helpVisible => dispatch => {
  dispatch({
    type: types.HELP_VISIBLE_UPDATE,
    payload: { helpVisible },
  });
};

export const updateIsHelpHideForever = isHelpHideForever => ({
  type: types.IS_HELP_HIDE_FOREVER,
  payload: { isHelpHideForever },
});

export const updateIsCancelDeliveryVisible = (isCancelDeliveryVisible, origin) => ({
  type: types.IS_CANCEL_DELIVERY_VISIBLE_UPDATE,
  payload: { isCancelDeliveryVisible, origin },
});

export const updateIsCallSupportVisible = isCallSupportVisible => ({
  type: types.IS_CALL_SUPPORT_VISIBLE_UPDATE,
  payload: { isCallSupportVisible },
});
