/* eslint-disable no-console */
import { FETCH_TIMEOUT, responseSuccess, responseError, getParamsFromBody } from './utils';
import { users, allowedPrefixes } from './data';

const boxResponse = responseSuccess({
  type: 'box',
  relationships: {
    transaction_id: 1,
  },
  attributes: {
    box_number: 69,
    opening_code: '8123',
    size: 'M',
  },
});

const boxNotAvailableError = responseError({ code: 'box_not_available' }, 400);
const invalidDataError = responseError({ code: 'invalid_data' }, 400);
const invalidDataPrefixError = responseError({ code: 'invalid_data' }, 400);
const userCreationForbiddenError = responseError({ code: 'user_autocreation_forbidden' }, 400);
const userBlockedError = responseError({ code: 'user_blocked' }, 403);
const invalidDataPrefixNotAllowedError = responseError({
  code: 'invalid_data',
  detail: { phone: "The phone number's country is not supported" },
});

const prefixRegEx = /^\+(?:[0-9]{1,3})/;
const hasAllowedPrefix = phone => allowedPrefixes.some(prefix => phone.includes(prefix));
const getUserByPhone = phone => users.find(user => phone.includes(user.id)) || {};

export const deliver = (resource, init) => {
  const body = getParamsFromBody(init);
  const userPhone = body.get('recipient_phone');
  let deliveryResponse;

  if (!prefixRegEx.test(userPhone)) {
    deliveryResponse = invalidDataPrefixError;
  } else if (hasAllowedPrefix(userPhone)) {
    const user = getUserByPhone(userPhone);

    switch (user.status) {
      case 'boxNotAvailable':
        deliveryResponse = boxNotAvailableError;
        break;
      case 'userRegistrationForbidden':
        deliveryResponse = userCreationForbiddenError;
        break;
      case 'userBlocked':
        deliveryResponse = userBlockedError;
        break;
      case 'invalidData':
        deliveryResponse = invalidDataError;
        break;
      default:
        deliveryResponse = boxResponse;
        break;
    }
  } else {
    deliveryResponse = invalidDataPrefixNotAllowedError;
  }

  const response = deliveryResponse();

  console.log('MOCK', resource, init, response);
  return new Promise(resolve => setTimeout(() => resolve(response), FETCH_TIMEOUT));
};

const retryResponseOk = responseSuccess({
  box_number: 1,
  opening_code: '1234',
});

// const retryCodesExceededError = responseError({ code: 'codes_request_exceeded' }, 400);

export const retryDelivery = (resource, init) => {
  const response = retryResponseOk();
  console.log('MOCK', resource, init, response);
  return new Promise(resolve => setTimeout(() => resolve(response), FETCH_TIMEOUT));
};

const deleteDeliveryResponse = responseSuccess('', 200);

export const deleteDelivery = (resource, init) => {
  const response = deleteDeliveryResponse();
  console.log('MOCK', resource, init, response);
  return new Promise(resolve => setTimeout(() => resolve(response), FETCH_TIMEOUT));
};
