export const LOADING_START = 'LOADING_START';
export const LOADING_STOP = 'LOADING_STOP';
export const HELP_VISIBLE_UPDATE = 'HELP_VISIBLE_UPDATE';
export const IS_HELP_HIDE_FOREVER = 'IS_HELP_HIDE_FOREVER';
export const IS_SCAN_SUCCESSFUL_UPDATE = 'IS_SCAN_SUCCESSFUL_UPDATE';
export const IS_SCAN_ERRONEOUS_UPDATE = 'IS_SCAN_ERRONEOUS_UPDATE';
export const IS_LOADING_SMS_UPDATE = 'IS_LOADING_SMS_UPDATE';
export const IS_SMS_SENT_UPDATE = 'IS_SMS_SENT_UPDATE';
export const IS_CODE_REQUEST_DISABLED_UPDATE = 'IS_CODE_REQUEST_DISABLED_UPDATE';
export const IS_AUTOFIX_ACCEPT_LOADING_UPDATE = 'IS_AUTOFIX_ACCEPT_LOADING_UPDATE';
export const IS_AUTOFIX_PRIMARY_LOADING_UPDATE = 'IS_AUTOFIX_PRIMARY_LOADING_UPDATE';
export const IS_AUTOFIX_SECONDARY_LOADING_UPDATE = 'IS_AUTOFIX_SECONDARY_LOADING_UPDATE';
export const AUTOFIX_VISIBLE_UPDATE = 'AUTOFIX_VISIBLE_UPDATE';
export const IS_CANCEL_DELIVERY_VISIBLE_UPDATE = 'IS_CANCEL_DELIVERY_VISIBLE_UPDATE';
export const IS_CALL_SUPPORT_VISIBLE_UPDATE = 'IS_CALL_SUPPORT_VISIBLE_UPDATE';
