export const trackPageView = (eventDef, tracker) => (action, prevState, nextState) => {
  const event = eventDef(action, prevState, nextState);
  const { page } = event;

  return {
    hitType: 'pageview',
    customTrackerId: tracker,
    page: `/${page}`,
  };
};

export const trackEvent = (eventDef, tracker) => (action, prevState, nextState) => {
  const event = eventDef(action, prevState, nextState);
  const {
    category: eventCategory,
    action: eventAction,
    label: eventLabel,
    value: eventValue,
  } = event;

  return {
    hitType: 'event',
    customTrackerId: tracker,
    eventCategory,
    eventAction,
    eventLabel,
    eventValue,
  };
};

export const setConfig = (configDef, tracker) => (action, prevState, nextState) => {
  const config = configDef(action, prevState, nextState);
  return {
    customTrackerId: tracker,
    config,
  };
};

export const trackException = (eventDef, tracker) => (action, prevState, nextState) => {
  const event = eventDef(action, prevState, nextState);
  const { exDescription, exFatal } = event;

  return {
    hitType: 'exception',
    customTrackerId: tracker,
    exDescription,
    exFatal,
  };
};
