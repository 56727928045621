import { schema } from 'normalizr';

const CARRIER = new schema.Entity('carriers');
const USER = new schema.Entity('users', { carrier: CARRIER }, { idAttribute: 'full_phone_number' });
const LOCATION = new schema.Entity('locations', {
  users: [USER],
});
const DELIVERY = new schema.Entity(
  'deliveries',
  {},
  {
    idAttribute: value => value.relationships.transaction_id,
  },
);

export const schemas = {
  USER,
  CARRIER,
  LOCATION,
  DELIVERY,
};
