import { validateFetchTypes } from '../utils';

const INIT_STATE = {
  isFetching: false,
  nextPageUrl: undefined,
  pageCount: 0,
  ids: [],
};
// Creates a reducer managing pagination, given the action types to handle,
// and a function telling how to extract the key from an action.
export const paginate = ({ types }) => {
  const [requestType, successType, failureType] = validateFetchTypes(types);

  const updatePagination = (state, action) => {
    switch (action.type) {
      case requestType:
        return {
          ...state,
          isFetching: true,
        };
      case successType:
        return {
          ...state,
          isFetching: false,
          ids: [...new Set([...action.payload.result])],
          nextPageUrl: action.nextPageUrl,
          pageCount: state.pageCount + 1,
        };
      case failureType:
        return {
          ...state,
          isFetching: false,
        };
      default:
        return state;
    }
  };

  return (state = INIT_STATE, action) =>
    // Update pagination by key
    ({
      ...state,
      ...updatePagination(state, action),
    });
};
