/* eslint-disable no-console */
import { FETCH_TIMEOUT, responseSuccess, getParamsFromURL, responseError } from './utils';
import { usersList } from './data';

const locationAvailableResponse = responseSuccess({
  id: 507,
  code: 'AAM00',
  available_boxes: true,
  address: 'Paseo de la Castellana, 141',
  boxes_info: [
    {
      size: 'S',
      height: 200,
      width: 340,
      length: 560,
      model: 'LBX10',
    },
    {
      size: 'M',
      height: 440,
      width: 340,
      length: 560,
      model: 'LBX10',
    },
    {
      size: 'L',
      height: 935,
      width: 340,
      length: 560,
      model: 'LBX10',
    },
  ],
});

const locationUnvailableResponse = responseSuccess({
  id: 508,
  code: 'AAM01',
  address: 'Paseo de la Castellana, 142',
  available_boxes: false,
  boxes_info: [],
});

const locationUnsupportedLockError = responseError({ code: 'unsupported_lock' }, 400);

/* eslint-disable camelcase */
const locations = (resource, init) => {
  const { label_code } = getParamsFromURL(resource);
  let locationResponse;

  switch (label_code) {
    case '123456':
      locationResponse = locationUnvailableResponse;
      break;
    case '-12345':
      locationResponse = locationUnsupportedLockError;
      break;
    default:
      locationResponse = locationAvailableResponse;
      break;
  }
  const response = locationResponse();

  console.log('MOCK', resource, init, response);
  return new Promise(resolve => setTimeout(() => resolve(response), FETCH_TIMEOUT));
};

const usersResponse = responseSuccess({
  count: 5,
  next: null,
  previous: null,
  results: usersList,
});

const locationUsers = (resource, init) => {
  const response = usersResponse();
  console.log('MOCK', resource, init, response);
  return new Promise(resolve => setTimeout(() => resolve(response), FETCH_TIMEOUT));
};
export { locations, locationUsers };
