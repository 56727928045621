import { connect } from 'pwa-helpers/connect-mixin';
import 'lit-element-router/router/router-slot';
import { CouriersAppView } from './couriers-app-view';
import { env } from '../env';
import { store } from '../store';
import {
  getRoute,
  getParams,
  getQuery,
  getHeader,
  getFlavour,
  getProgress,
  hasFooter,
  getAppMessage,
  getCode,
  getCookiesLoaded,
  getLang,
  isRehydrated,
} from '../selectors';
import {
  updateOffline,
  updateRouter,
  updateVersion,
  headerButton,
  replaceStep,
  headerCallToAction,
  setCookies,
  setLang,
  setAppVersion,
  setUpdateVersion,
  shouldCancelBackwardsNavigation,
  cancelBackwardsNavigation,
} from '../actions';
import { didUserConsent } from '../services/cookiebot';
import { isLatestVersion } from '../services/latest-version';

const UPDATE_VERSION_TIMEOUT = 5000;

export class CouriersApp extends connect(store)(CouriersAppView) {
  constructor() {
    super();

    this.addEventListener('lang-changed', event => {
      store.dispatch(setLang(event.detail));
    });

    this.addEventListener('cookiebot-accept', () => {
      store.dispatch(setCookies());
      if (didUserConsent('statistics')) {
        store.dispatch(setAppVersion(env.APP_VERSION));
      }
    });

    this.addEventListener('invalid-route', () => {
      store.dispatch(replaceStep('', '/'));
    });

    this.addEventListener('update-route', ({ detail: { route, params, query, data } }) => {
      store.dispatch(updateRouter({ route, params, query, data }));
    });

    this.addEventListener('update-offline', ({ detail }) => {
      store.dispatch(updateOffline(detail));
    });

    this.addEventListener('update-version-clicked', () => {
      store.dispatch(updateVersion());
    });

    this.addEventListener('history-changed', () => {
      const shouldCancelNavigation = store.dispatch(shouldCancelBackwardsNavigation());

      if (shouldCancelNavigation) {
        store.dispatch(cancelBackwardsNavigation());
      }
    });
  }

  firstUpdated() {
    super.firstUpdated();

    this._checkCurrentVersion();
  }

  navButtonClicked(event) {
    super.navButtonClicked(event);
    store.dispatch(headerButton());
  }

  // eslint-disable-next-line class-methods-use-this
  ctaClick() {
    store.dispatch(headerCallToAction(this._lang));
  }

  // eslint-disable-next-line class-methods-use-this
  async _checkCurrentVersion() {
    const isLatest = await isLatestVersion();

    if (!isLatest) {
      setTimeout(() => {
        store.dispatch(setUpdateVersion());
      }, UPDATE_VERSION_TIMEOUT);
    }
  }

  stateChanged(state) {
    this._cookiesLoaded = getCookiesLoaded(state);
    this.route = getRoute(state);
    this.params = getParams(state);
    this.query = getQuery(state);
    this.header = getHeader(state);
    this.hasFooter = hasFooter(state);
    this.flavour = getFlavour(state);
    this.progress = getProgress(state);
    this._appMessage = getAppMessage(state);
    this.code = getCode(state);
    this.isRehydrated = isRehydrated(state);
    this._lang = getLang(state);
  }
}
