import { removeKeys } from '../utils/removeKeys';

export function GoogleAnalytics() {
  if (typeof window === 'undefined') {
    return () => {};
  }
  if (typeof window.ga !== 'function') {
    // eslint-disable-next-line no-console
    console.warn(`Analytics are not being tracked, window.ga is not a function.
  Please include the Analytics Tag snippet: https://support.google.com/analytics/answer/1008080`);
    return () => {};
  }

  return function target(events) {
    events.forEach(event => {
      const customTrackers = event.customTrackerId || event.tracker;
      const trackerIdsRaw = Array.isArray(customTrackers) ? customTrackers : [customTrackers];
      const trackerIds = trackerIdsRaw.map(trackerId =>
        !!trackerId && !!trackerId.trim() ? `${trackerId}.` : '',
      );
      trackerIds.forEach(trackerId => {
        if (event.hitType) {
          if (event.hitType === 'pageview') {
            ga(`${trackerId}set`, 'page', event.page);
          }
          ga(`${trackerId}send`, removeKeys(event, ['customTrackerId']));
        } else if (event.config) {
          Object.keys(event.config).forEach(key => {
            ga(`${trackerId}set`, key, event.config[key]);
          });
        }
      });
    });
  };
}
