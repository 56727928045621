import * as types from '../../actions/types';

export const actionTransformer = action => {
  /* Action's payload that should be modified before logged */
  const scrubbedActions = [
    types.SET_TOKEN,
    types.COURIER_TOKEN_REQUEST_SUCCESS,
    types.COURIER_SET_PIN,
  ];
  if (scrubbedActions.includes(action.type)) {
    return {
      ...action,
      payload: null,
    };
  }

  /* Actions that should not to be logged */
  const excludedActions = [
    'persist/PERSIST',
    'persist/REHYDRATE',
    types.LOADING_START,
    types.LOADING_STOP,
  ];
  if (excludedActions.includes(action.type)) {
    return null;
  }

  return action;
};
