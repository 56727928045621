import { createTransform } from 'redux-persist';

export const transformExpire = (expireIn, expireKey = 'persistencyExpiration') => {
  try {
    let expired = false;
    const storedExpiration = localStorage.getItem(expireKey);

    if (storedExpiration) {
      const expiring = parseInt(storedExpiration, 10);
      const now = new Date().getTime();
      expired = expiring && now > expiring;
    }

    return createTransform(
      inboundState => {
        setTimeout(
          () => localStorage.setItem(expireKey, (new Date().getTime() + expireIn).toString()),
          0,
        );
        return inboundState;
      },
      outboundState => (expired ? {} : outboundState),
    );
  } catch (error) {
    return createTransform();
  }
};
