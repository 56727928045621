export const couriers = [
  { id: '601000000', status: 'guest' },
  { id: '602000000', status: 'courier' },
  { id: '603000000', status: 'registered', role: 19 },
  { id: '603100000', status: 'registered', role: 29 },
  { id: '604000000', status: 'unregistered' },
  { id: '605000000', status: 'invalidPhone' },
  { id: '606000000', status: 'invalidType' },
  { id: '607000000', status: 'invalidData' },
  { id: '608000000', status: 'userBlocked' },
];
export const allowedPrefixes = ['+33', '+34'];
export const users = [
  { id: '612345677', status: 'boxNotAvailable' },
  { id: '612345671', status: 'userRegistrationForbidden' },
  { id: '612345678', status: 'userBlocked' },
  { id: '612345670', status: 'invalidData' },
];

export const usersList = [
  {
    name: 'Laura Palmer',
    phone: '612345678',
    full_phone_number: '+34612345678',
    is_active: false,
  },
  {
    name: 'Diego Castaño',
    phone: '612345679',
    full_phone_number: '+34612345679',
    is_active: true,
  },
  {
    name: 'Jorge del Casar',
    phone: '612345670',
    full_phone_number: '+34612345670',
    is_active: true,
  },
  {
    name: 'Fernando Roldán',
    phone: '612345671',
    full_phone_number: '+34612345671',
    is_active: true,
  },
  {
    name: 'Casimiro González',
    phone: '612345677',
    full_phone_number: '+34612345677',
    is_active: true,
  },
  {
    name: 'Marcelino Pan Feliz',
    phone: '678901234',
    full_phone_number: '+34678901234',
    is_active: true,
  },
  {
    name: 'David Almazán',
    phone: '0612345674',
    full_phone_number: '+34612345674',
    is_active: false,
  },
];
export const usersObject = usersList.reduce((acc, curr) => ({ ...acc, [curr.phone]: curr }), {});
