import { css, unsafeCSS } from 'lit-element';
import { commonStyles, customProperties, typographies } from '@citibox/lava-typography';
import { citiboxColors, componentsColors, flavours } from '@citibox/flavours';

export const themeStyles = css`
  ${commonStyles} :host {
    ${unsafeCSS(typographies.quicksandLato)}
    ${unsafeCSS(customProperties)};
    ${unsafeCSS(citiboxColors)};
    ${unsafeCSS(componentsColors)};
    background: rgb(var(--primary-color));
  }

  :host([flavour='darkGrey']) {
    ${unsafeCSS(flavours.darkGrey)};
  }

  :host([flavour='lightGrey']) {
    ${unsafeCSS(flavours.lightGrey)};
  }

  :host([flavour='darkParty']) {
    ${unsafeCSS(flavours.darkParty)};
  }

  :host([flavour='lightParty']) {
    ${unsafeCSS(flavours.lightGrey)};
  }

  :host([flavour='darkGrey']) section-info,
  :host([flavour='darkGrey']) section-footer {
    ${unsafeCSS(flavours.lightGrey)};
    ${unsafeCSS(componentsColors)};
  }

  :host([flavour='lightGrey']) section-info,
  :host([flavour='lightGrey']) section-footer,
  :host([flavour='lightGrey']) modal-dialog {
    ${unsafeCSS(flavours.darkGrey)};
    ${unsafeCSS(componentsColors)};
  }

  :host([flavour='darkParty']) section-info,
  :host([flavour='darkParty']) section-footer {
    ${unsafeCSS(flavours.lightParty)};
    ${unsafeCSS(componentsColors)};
  }

  :host([flavour='lightParty']) section-info,
  :host([flavour='lightParty']) section-footer {
    ${unsafeCSS(flavours.darkParty)};
    ${unsafeCSS(componentsColors)};
  }

  section-hero {
    height: var(--page-min-height, calc(100vh - 80px));
  }
`;
