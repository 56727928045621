import { SentryReporter } from '@citibox/error-reporter';
import { actionTransformer, stateTransformer } from '../transformers';
import { getCourierId } from '../../selectors';

export const sentryMiddleware = ({ getState }) => next => action => {
  SentryReporter.reporter.configureScope(scope => {
    /* Add actions as breadcrumbs */
    const transformedAction = actionTransformer(action);
    if (transformedAction) {
      scope.addBreadcrumb({
        category: 'redux-action',
        message: transformedAction.type,
        data: transformedAction.payload,
        level: 'info',
      });
    }

    /* Set latest state to scope */
    const transformedState = stateTransformer(getState());
    scope.setContext('webapp state', transformedState);

    /* Identify user */
    const { id } = scope.getUser();

    if (!id) {
      const courierId = getCourierId(getState());
      if (courierId) {
        scope.setUser({ id: courierId });
      }
    }
  });

  return next(action);
};
