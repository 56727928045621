import * as types from './types';

export const consumerSelected = ({ consumerName, consumerPhone }) => ({
  type: types.CONSUMER_SELECTED,
  payload: { consumerName, consumerPhone },
});

export const onNewConsumerNext = () => ({
  type: types.NEW_CONSUMER_NEXT,
});

export const confirmConsumerModal = () => ({
  type: types.CONFIRM_CONSUMER_MODAL,
});

export const cancelConsumerModal = () => ({
  type: types.CANCEL_CONSUMER_MODAL,
});

export const closeConsumerModal = () => ({
  type: types.CLOSE_CONSUMER_MODAL,
});

export const addNewUser = source => ({
  type: types.ADD_NEW_USER,
  payload: { source },
});
