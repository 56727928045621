/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import { FETCH_TIMEOUT, responseSuccess, getParamsFromURL } from './utils';
import { getCarriersList } from './data';

const getCarriersOK = country =>
  responseSuccess(
    {
      count: 29,
      next: `https://ma1-test-dot-app-service-dot-citibox-dev.appspot.com/app_service/couriers/v1/carriers?limit=5&offset=5&sort=-order&country=${country}`,
      previous: null,
      results: getCarriersList(country),
    },
    200,
  );

const getCarriers = (resource, init) => {
  const queryParams = getParamsFromURL(resource);
  const response = getCarriersOK(queryParams.country)();
  console.log('MOCK', resource, init, response);
  return new Promise(resolve => setTimeout(() => resolve(response), FETCH_TIMEOUT));
};

const postCarrierOK = responseSuccess({ id: 9999 });

const postCarrier = (resource, init) => {
  const response = postCarrierOK();
  console.log('MOCK', resource, init, response);
  return new Promise(resolve => setTimeout(() => resolve(response), FETCH_TIMEOUT));
};

export { getCarriers, postCarrier };
