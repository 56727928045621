import * as types from '../actions/types';

const INITIAL_UI_STATE = {
  isScanSuccessful: false,
  isScanInvalid: false,
  isLoading: false,
  isLoadingSms: false,
  autofixVisible: false,
  autofixAcceptLoading: false,
  autofixPrimaryLoading: false,
  autofixSecondaryLoading: false,
  isSmsSent: false,
  isCodeRequestDisabled: false,
  helpVisible: false,
  isHelpHideForever: false,
  isCancelDeliveryVisible: false,
  isCallSupportVisible: false,
};

export const ui = (state = INITIAL_UI_STATE, action) => {
  switch (action.type) {
    case types.HELP_VISIBLE_UPDATE:
    case types.IS_HELP_HIDE_FOREVER:
    case types.IS_SCAN_SUCCESSFUL_UPDATE:
    case types.IS_SCAN_ERRONEOUS_UPDATE:
    case types.IS_LOADING_SMS_UPDATE:
    case types.IS_SMS_SENT_UPDATE:
    case types.IS_CODE_REQUEST_DISABLED_UPDATE:
    case types.IS_AUTOFIX_ACCEPT_LOADING_UPDATE:
    case types.IS_AUTOFIX_PRIMARY_LOADING_UPDATE:
    case types.IS_AUTOFIX_SECONDARY_LOADING_UPDATE:
    case types.AUTOFIX_VISIBLE_UPDATE:
    case types.IS_CANCEL_DELIVERY_VISIBLE_UPDATE:
    case types.IS_CALL_SUPPORT_VISIBLE_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case types.LOADING_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.LOADING_STOP:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return {
        ...state,
      };
  }
};
