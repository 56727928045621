import { RSAA } from 'redux-api-middleware';
import { normalizeJSON, getResponse, normalizeResponse, schemas } from '../../api';
import { getDeliveryLocationId, deliveryIdSelector } from '../../selectors';

export const COURIER_STATUS_REQUEST = 'COURIER_STATUS_REQUEST';
export const COURIER_STATUS_SUCCESS = 'COURIER_STATUS_SUCCESS';
export const COURIER_STATUS_FAILURE = 'COURIER_STATUS_FAILURE';
export const COURIER_STATUS_RSAA = [
  COURIER_STATUS_REQUEST,
  COURIER_STATUS_SUCCESS,
  COURIER_STATUS_FAILURE,
];

export const SMS_VALIDATION_REQUEST = 'SMS_VALIDATION_REQUEST';
export const SMS_VALIDATION_SUCCESS = 'SMS_VALIDATION_SUCCESS';
export const SMS_VALIDATION_FAILURE = 'SMS_VALIDATION_FAILURE';
export const SMS_VALIDATION_RSAA = [
  SMS_VALIDATION_REQUEST,
  SMS_VALIDATION_SUCCESS,
  SMS_VALIDATION_FAILURE,
];

export const VERIFY_PHONE_REQUEST = 'VERIFY_PHONE_REQUEST';
export const VERIFY_PHONE_SUCCESS = 'VERIFY_PHONE_SUCCESS';
export const VERIFY_PHONE_FAILURE = 'VERIFY_PHONE_FAILURE';
export const VERIFY_PHONE_RSAA = [VERIFY_PHONE_REQUEST, VERIFY_PHONE_SUCCESS, VERIFY_PHONE_FAILURE];

export const COURIER_GUEST_CREATE_REQUEST = 'COURIER_GUEST_CREATE_REQUEST';
export const COURIER_GUEST_CREATE_SUCCESS = 'COURIER_GUEST_CREATE_SUCCESS';
export const COURIER_GUEST_CREATE_FAILURE = 'COURIER_GUEST_CREATE_FAILURE';
export const COURIER_GUEST_CREATE_RSAA = [
  COURIER_GUEST_CREATE_REQUEST,
  COURIER_GUEST_CREATE_SUCCESS,
  COURIER_GUEST_CREATE_FAILURE,
];

export const CARRIERS_REQUEST = 'CARRIERS_REQUEST';
export const CARRIERS_SUCCESS = 'CARRIERS_SUCCESS';
export const CARRIERS_FAILURE = 'CARRIERS_FAILURE';
export const CARRIERS_REQUEST_RSAA = [
  CARRIERS_REQUEST,
  {
    type: CARRIERS_SUCCESS,
    payload: normalizeResponse([schemas.CARRIER]),
  },
  CARRIERS_FAILURE,
];

export const CARRIERS_CREATE_REQUEST = 'CARRIERS_CREATE_REQUEST';
export const CARRIERS_CREATE_SUCCESS = 'CARRIERS_CREATE_SUCCESS';
export const CARRIERS_CREATE_FAILURE = 'CARRIERS_CREATE_FAILURE';
export const CARRIERS_CREATE_RSAA = [
  CARRIERS_CREATE_REQUEST,
  {
    type: CARRIERS_CREATE_SUCCESS,
    payload: (action, state, res) => {
      const body = JSON.parse(action[RSAA].body);
      return getResponse(action, state, res)
        .then(json => ({
          ...body,
          ...json,
        }))
        .then(normalizeJSON(schemas.CARRIER));
    },
  },
  CARRIERS_CREATE_FAILURE,
];

export const COURIER_TOKEN_REQUEST_REQUEST = 'COURIER_TOKEN_REQUEST_REQUEST';
export const COURIER_TOKEN_REQUEST_SUCCESS = 'COURIER_TOKEN_REQUEST_SUCCESS';
export const COURIER_TOKEN_REQUEST_FAILURE = 'COURIER_TOKEN_REQUEST_FAILURE';
export const COURIER_TOKEN_REQUEST_RSAA = [
  COURIER_TOKEN_REQUEST_REQUEST,
  COURIER_TOKEN_REQUEST_SUCCESS,
  COURIER_TOKEN_REQUEST_FAILURE,
];

export const GUEST_PROFILE_REQUEST_REQUEST = 'GUEST_PROFILE_REQUEST_REQUEST';
export const GUEST_PROFILE_REQUEST_SUCCESS = 'GUEST_PROFILE_REQUEST_SUCCESS';
export const GUEST_PROFILE_REQUEST_FAILURE = 'GUEST_PROFILE_REQUEST_FAILURE';
export const GUEST_PROFILE_REQUEST_RSAA = [
  GUEST_PROFILE_REQUEST_REQUEST,
  {
    type: GUEST_PROFILE_REQUEST_SUCCESS,
    payload: normalizeResponse(schemas.USER),
  },
  GUEST_PROFILE_REQUEST_FAILURE,
];

export const USER_PROFILE_REQUEST_REQUEST = 'USER_PROFILE_REQUEST_REQUEST';
export const USER_PROFILE_REQUEST_SUCCESS = 'USER_PROFILE_REQUEST_SUCCESS';
export const USER_PROFILE_REQUEST_FAILURE = 'USER_PROFILE_REQUEST_FAILURE';
export const USER_PROFILE_REQUEST_RSAA = [
  USER_PROFILE_REQUEST_REQUEST,
  {
    type: USER_PROFILE_REQUEST_SUCCESS,
    payload: normalizeResponse(schemas.USER),
  },
  USER_PROFILE_REQUEST_FAILURE,
];

export const LOCATIONS_REQUEST_REQUEST = 'LOCATIONS_REQUEST_REQUEST';
export const LOCATIONS_REQUEST_SUCCESS = 'LOCATIONS_REQUEST_SUCCESS';
export const LOCATIONS_REQUEST_FAILURE = 'LOCATIONS_REQUEST_FAILURE';
export const LOCATIONS_REQUEST_RSAA = [
  LOCATIONS_REQUEST_REQUEST,
  {
    type: LOCATIONS_REQUEST_SUCCESS,
    payload: normalizeResponse(schemas.LOCATION),
  },
  LOCATIONS_REQUEST_FAILURE,
];

export const LOCATION_USERS_REQUEST_REQUEST = 'LOCATION_USERS_REQUEST_REQUEST';
export const LOCATION_USERS_REQUEST_SUCCESS = 'LOCATION_USERS_REQUEST_SUCCESS';
export const LOCATION_USERS_REQUEST_FAILURE = 'LOCATION_USERS_REQUEST_FAILURE';
export const LOCATION_USERS_REQUEST_RSAA = [
  LOCATION_USERS_REQUEST_REQUEST,
  {
    type: LOCATION_USERS_REQUEST_SUCCESS,
    payload: (action, state, res) =>
      getResponse(action, state, res)
        .then(json => ({
          id: getDeliveryLocationId(state),
          users: json.results,
        }))
        .then(normalizeJSON(schemas.LOCATION)),
  },
  LOCATION_USERS_REQUEST_FAILURE,
];

export const POST_DELIVERY_REQUEST = 'POST_DELIVERY_REQUEST';
export const POST_DELIVERY_SUCCESS = 'POST_DELIVERY_SUCCESS';
export const POST_DELIVERY_FAILURE = 'POST_DELIVERY_FAILURE';
export const POST_DELIVERY_REQUEST_RSAA = [
  POST_DELIVERY_REQUEST,
  {
    type: POST_DELIVERY_SUCCESS,
    payload: normalizeResponse(schemas.DELIVERY),
  },
  POST_DELIVERY_FAILURE,
];

export const RETRY_DELIVERY_SUCCESS = 'RETRY_DELIVERY_SUCCESS';
export const RETRY_DELIVERY_FAILURE = 'RETRY_DELIVERY_FAILURE';
export const RETRY_DELIVERY_REQUEST = 'RETRY_DELIVERY_REQUEST';
export const RETRY_DELIVERY_REQUEST_RSAA = [
  RETRY_DELIVERY_REQUEST,
  {
    type: RETRY_DELIVERY_SUCCESS,
    payload: (action, state, res) =>
      getResponse(action, state, res)
        .then(json => ({
          relationships: {
            transaction_id: deliveryIdSelector(state),
          },
          attributes: json,
        }))
        .then(normalizeJSON(schemas.DELIVERY)),
  },
  RETRY_DELIVERY_FAILURE,
];

export const DELETE_DELIVERY_REQUEST = 'DELETE_DELIVERY_REQUEST';
export const DELETE_DELIVERY_SUCCESS = 'DELETE_DELIVERY_SUCCESS';
export const DELETE_DELIVERY_FAILURE = 'DELETE_DELIVERY_FAILURE';
export const DELETE_DELIVERY_REQUEST_RSAA = [
  DELETE_DELIVERY_REQUEST,
  DELETE_DELIVERY_SUCCESS,
  DELETE_DELIVERY_FAILURE,
];
