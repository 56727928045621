/* eslint-disable */
import { env } from '../env';

// Allow async usage of ga()
window.ga = function() {
  (ga.q = ga.q || []).push(arguments);
};
ga.l = +new Date();

export const initGA = () => {
  const { GOOGLE_ANALYTICS_PROPERTY_ID } = env;

  if (GOOGLE_ANALYTICS_PROPERTY_ID) {
    (function(i, s, o, g, r, a, m) {
      i.GoogleAnalyticsObject = r;
      (i[r] =
        i[r] ||
        function() {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');

    const ga = window['ga'];

    ga('create', window.process.env.GOOGLE_ANALYTICS_PROPERTY_ID, 'auto');
  }
};
