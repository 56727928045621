import { createSelector } from 'reselect';

const appSelector = state => state.app;
export const getHeader = createSelector(appSelector, app => app.header);
export const getIsNewDeliveryProcess = createSelector(appSelector, app => app.isNewDeliveryProcess);
export const hasFooter = createSelector(appSelector, app => app.hasFooter);
export const getFlavour = createSelector(appSelector, app => app.flavour);
export const getFlow = createSelector(appSelector, app => app.flow);
export const getProgress = createSelector(appSelector, app => app.progress);
export const getOffline = createSelector(appSelector, app => app.offline);
export const getAppMessage = createSelector(appSelector, app => app.appMessage);
export const getErrorMessage = createSelector(appSelector, app => app.errorMessage);
export const getActiveScanning = createSelector(appSelector, app => app.activeScanning);
export const getCookiesLoaded = createSelector(appSelector, app => app.cookiesLoaded);
export const getLang = createSelector(appSelector, app => app.lang);

const routerSelector = createSelector(appSelector, app => app.router);
export const getRoute = createSelector(routerSelector, router => router.route);
export const getParams = createSelector(routerSelector, router => router.params);
export const getQuery = createSelector(routerSelector, router => router.query);
