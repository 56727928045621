import * as api from '../api';
import * as types from '../types';
import { setErrorMessage, resetErrorMessage } from '../app';
import { setToken, setCarrier } from '../courier';
import { updateIsCancelDeliveryVisible } from '../ui';
import { addNewUser } from '../consumer';
import {
  getRoute,
  getFlow,
  getHeader,
  getPhone,
  getCourierId,
  getCarrierId,
  getRoleId,
  getDeliveryId,
  getCarrierAllowProvisionalUsers,
} from '../../selectors';

export const timeouts = {
  qrScannerTimeout: null,
  barcodeScannerTimeout: null,
};

let loadingTimePayload = {};

const getNextStepLoadingPayload = ({ action, extras }) => (dispatch, getState) => ({
  route: getRoute(getState()),
  timestamp: performance.now(),
  action,
  ...extras,
});

export const startLoading = ({ action, extras }) => async dispatch => {
  if (!action) {
    // eslint-disable-next-line no-console
    console.warn('startLoading requires an action argument');
  }
  loadingTimePayload = await dispatch(getNextStepLoadingPayload({ action, extras }));
  return dispatch({ type: types.LOADING_START, payload: loadingTimePayload });
};

export const stopLoading = action => dispatch => {
  if (loadingTimePayload.action !== action) {
    // eslint-disable-next-line no-console
    console.warn("stopLoading action doesn't match with the startLoading one");
  }
  loadingTimePayload.diff = Math.round(performance.now() - loadingTimePayload.timestamp);
  return dispatch({ type: types.LOADING_STOP, payload: loadingTimePayload });
};

export const createTimeout = (id, fn, delay) => {
  timeouts[id] = setTimeout(fn, delay);
};

const deleteTimeout = timeoutID => {
  if (timeouts[timeoutID]) {
    clearTimeout(timeouts[timeoutID]);
    timeouts[timeoutID] = null;
  }
};

const dispatchEventRoute = () => {
  window.dispatchEvent(new CustomEvent('route'));
};

const replaceState = path => {
  window.history.replaceState({}, null, path);
  dispatchEventRoute();
};

export const pushState = path => {
  window.history.pushState({}, null, path);
  dispatchEventRoute();
};

const getFlowStep = (state, step, fallback = '') => {
  if (step) {
    const flow = getFlow(state);
    if (flow && flow[step]) {
      return flow[step];
    }
  }
  return fallback;
};

export const nextStep = step => (dispatch, getState) => {
  const state = getState();
  const path = getFlowStep(state, step);
  deleteTimeout('qrScannerTimeout');
  deleteTimeout('barcodeScannerTimeout');

  if (path) {
    pushState(path);
  }
};

export const replaceStep = (step, fallback) => (dispatch, getState) => {
  const state = getState();
  const path = getFlowStep(state, step, fallback);
  if (path) {
    replaceState(path);
  }
};

export const headerCallToAction = lang => (dispatch, getState) => {
  const state = getState();
  const { cta } = getHeader(state);
  const carrierAllowProvisionalUsers = getCarrierAllowProvisionalUsers(state);

  switch (cta) {
    case 'help':
      window.open(`https://help.citibox.com/hc/${lang}/categories/360001556937-Soy-mensajero`);
      break;
    case 'delete':
      dispatch(updateIsCancelDeliveryVisible(true, 'bin'));
      break;

    case 'add-user':
      if (!carrierAllowProvisionalUsers) {
        dispatch({
          type: types.PROVISIONAL_CONSUMER_NOT_ALLOWED,
        });
        dispatch(nextStep('noProvisionalUsers'));
      } else {
        dispatch(addNewUser('list'));
        dispatch(nextStep('new'));
      }

      dispatch(addNewUser('header'));
      dispatch(nextStep('new'));
      break;

    default:
      break;
  }

  return dispatch({
    type: types.HEADER_CTA,
    payload: cta,
  });
};

export const cancelBackwardsNavigation = () => dispatch => {
  window.history.go(1);
  setTimeout(() => {
    dispatch(updateIsCancelDeliveryVisible(true, 'back'));
  }, 300);
};

export const shouldCancelBackwardsNavigation = () => (dispatch, getState) => {
  const deliveryId = getDeliveryId(getState());
  return deliveryId.length > 0;
};

export const headerButton = () => dispatch => {
  const shouldCancelNavigation = dispatch(shouldCancelBackwardsNavigation());

  if (shouldCancelNavigation) {
    return dispatch(cancelBackwardsNavigation());
  }

  dispatch({
    type: types.HEADER_NAV_BUTTON,
  });
  window.history.go(-1);
  dispatchEventRoute();
  return dispatch(resetErrorMessage());
};

const loginUser = password => async (dispatch, getState) => {
  const phone = getPhone(getState());
  const body = { username: phone };
  if (password) {
    body.password = password;
  }
  const apiResponse = await dispatch(api.loginUser(body));
  if (apiResponse.error) {
    const { response: { code } = {} } = apiResponse.payload;
    switch (code) {
      case 'invalid_credentials':
        await dispatch(setErrorMessage('invalid_credentials'));
        break;
      case 'user_blocked':
        await dispatch(setErrorMessage('user_blocked'));
        break;
      default:
        await dispatch(setErrorMessage('something_went_wrong_try_again'));
        break;
    }
    throw new Error();
  } else {
    await dispatch(setToken(apiResponse.payload));
  }
};

const fetchGuestProfile = () => async (dispatch, getState) => {
  const apiResponse = await dispatch(api.fetchGuestProfile());
  if (apiResponse.error) {
    throw new Error(apiResponse.payload);
  }

  const state = getState();
  const carrierId = getCarrierId(state);
  await dispatch(setCarrier({ id: carrierId }));
  return dispatch({
    type: types.FLOW_GUEST_PROFILE,
    payload: {
      courierId: getCourierId(state),
      carrierId,
      roleId: getRoleId(state),
    },
  });
};

const fetchUserProfile = () => async (dispatch, getState) => {
  const apiResponse = await dispatch(api.fetchUserProfile());
  if (apiResponse.error) {
    throw new Error(apiResponse.payload);
  }

  const state = getState();
  return dispatch({
    type: types.FLOW_USER_PROFILE,
    payload: {
      courierId: getCourierId(state),
      roleId: getRoleId(state),
    },
  });
};

export const loginAndProfile = password => async dispatch => {
  await dispatch(loginUser(password));

  if (password) {
    return dispatch(fetchUserProfile());
  }
  return dispatch(fetchGuestProfile());
};
