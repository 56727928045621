import { SentryReporter } from '@citibox/error-reporter';

import { env } from '../env';

const shouldBeWarningMessages = [
  'You are currently using minified code outside of NODE_ENV === "production"',
  'TypeError: Failed to fetch dynamically imported module',
];

const ignoreErrors = [
  'TypeError: cancelled',
  'TypeError: cancelado',
  'TypeError: annulé',
  'TypeError: NetworkError when attempting to fetch resource',
];

export const initErrorReporting = () => {
  const projectEnv = env.NODE_ENV === 'production' ? 'citibox-pro' : 'citibox-dev';

  if (env.DOMAIN) {
    const { APP_NAME, APP_VERSION, SENTRY_DSN } = env;
    const depth = 6;

    SentryReporter.init({
      dsn: SENTRY_DSN,
      environment: projectEnv,
      release: `${APP_NAME}@${APP_VERSION}`,
      normalizeDepth: depth + 1,
      ignoreErrors,
      preprocess: {
        messages: {
          warning: shouldBeWarningMessages,
        },
      },
      integrations: [{ name: 'extraErrorData', options: { depth } }],
      /* tracesSampleRate: 0.25, */ // TODO: requires sentry-trace header to be accepted by the server -> Access-Control-Allow-Headers: sentry-trace
    });
    SentryReporter.reporter.setTag('webapp.version', APP_VERSION);

    let displayMode = 'browser';
    if (window.matchMedia && window.matchMedia('(display-mode: standalone)').matches) {
      displayMode = 'standalone';
    }
    SentryReporter.reporter.setTag('webapp.mode', displayMode);
  }
};

initErrorReporting();
