import { RSAA } from 'redux-api-middleware';
import { API_ROOT } from '../api';
import * as types from './types';
import { getToken } from '../selectors';
import * as mock from '../mock';

const IS_MOCK = API_ROOT.indexOf('citibox.mock') !== -1;

const getHeaders = () => ({
  'Content-Type': 'application/json',
});

const getAuthorizationHeader = state => ({
  ...getHeaders(),
  Authorization: `Bearer ${getToken(state).access_token}`,
});

export const getUserStatus = ({ phone }) => ({
  [RSAA]: {
    endpoint: `${API_ROOT}/users/status?phone=${encodeURIComponent(phone)}`,
    method: 'GET',
    headers: getHeaders,
    types: types.COURIER_STATUS_RSAA,
    fetch: IS_MOCK ? mock.status : fetch,
  },
});

export const sendSMS = body => ({
  [RSAA]: {
    endpoint: `${API_ROOT}/sms-verifications/`,
    method: 'POST',
    headers: getHeaders,
    types: types.SMS_VALIDATION_RSAA,
    body: JSON.stringify(body),
    fetch: IS_MOCK ? mock.sendSMS : fetch,
  },
});

export const verifyPhone = body => ({
  [RSAA]: {
    endpoint: `${API_ROOT}/sms-verifications/?phone=${encodeURIComponent(
      body.phone,
    )}&introduced_pin=${body.introduced_pin}`,
    method: 'GET',
    headers: getHeaders,
    types: types.VERIFY_PHONE_RSAA,
    fetch: IS_MOCK ? mock.verifyPhone : fetch,
  },
});

export const createGuest = body => ({
  [RSAA]: {
    endpoint: `${API_ROOT}/guests`,
    method: 'POST',
    headers: getHeaders,
    body: JSON.stringify(body),
    types: types.COURIER_GUEST_CREATE_RSAA,
    fetch: IS_MOCK ? mock.createGuest : fetch,
  },
});

export const loginUser = body => ({
  [RSAA]: {
    endpoint: `${API_ROOT}/tokens`,
    method: 'POST',
    headers: getHeaders,
    body: JSON.stringify({
      client_id: window.process.env.CITIBOX_CLIENT_ID,
      ...body,
    }),
    types: types.COURIER_TOKEN_REQUEST_RSAA,
    fetch: IS_MOCK ? mock.loginUser : fetch,
  },
});

export const fetchCarriers = alpha2Code => ({
  [RSAA]: {
    endpoint: `${API_ROOT}/carriers?sort=-order&country=${alpha2Code}`,
    method: 'GET',
    headers: getHeaders,
    types: types.CARRIERS_REQUEST_RSAA,
    fetch: IS_MOCK ? mock.getCarriers : fetch,
  },
});

export const postCarrier = body => ({
  [RSAA]: {
    endpoint: `${API_ROOT}/carriers`,
    method: 'POST',
    headers: getHeaders,
    body: JSON.stringify(body),
    types: types.CARRIERS_CREATE_RSAA,
    fetch: IS_MOCK ? mock.postCarrier : fetch,
  },
});

export const fetchGuestProfile = () => ({
  [RSAA]: {
    endpoint: `${API_ROOT}/guests/me`,
    method: 'GET',
    headers: getAuthorizationHeader,
    types: types.GUEST_PROFILE_REQUEST_RSAA,
    fetch: IS_MOCK ? mock.guestProfile : fetch,
  },
});

export const fetchUserProfile = () => ({
  [RSAA]: {
    endpoint: `${API_ROOT}/users/me`,
    method: 'GET',
    headers: getAuthorizationHeader,
    types: types.USER_PROFILE_REQUEST_RSAA,
    fetch: IS_MOCK ? mock.userProfile : fetch,
  },
});

export const fetchLocations = ({ labelCode }) => ({
  [RSAA]: {
    endpoint: `${API_ROOT}/locations?label_code=${encodeURIComponent(labelCode)}`,
    method: 'GET',
    headers: getAuthorizationHeader,
    types: types.LOCATIONS_REQUEST_RSAA,
    fetch: IS_MOCK ? mock.locations : fetch,
  },
});

export const fetchLocationsByLocationId = ({ locationId }) => ({
  [RSAA]: {
    endpoint: `${API_ROOT}/locations?location_id=${encodeURIComponent(locationId)}`,
    method: 'GET',
    headers: getAuthorizationHeader,
    types: types.LOCATIONS_REQUEST_RSAA,
    fetch: IS_MOCK ? mock.locations : fetch,
  },
});

export const fetchLocationUsers = ({ locationId, query }) => ({
  [RSAA]: {
    endpoint: `${API_ROOT}/locations/${locationId}/users?offset=0&limit=10&query=${encodeURIComponent(
      query,
    )}`,
    method: 'GET',
    headers: getAuthorizationHeader,
    types: types.LOCATION_USERS_REQUEST_RSAA,
    fetch: IS_MOCK ? mock.locationUsers : fetch,
  },
});

export const postDelivery = ({
  locationId,
  consumerPhone,
  consumerName,
  size,
  tracking,
  trackingImage,
  carrierId,
}) => async dispatch => {
  const formData = new FormData();
  formData.append('location_id', locationId);
  formData.append('recipient_phone', consumerPhone);
  formData.append('recipient_name', consumerName);
  formData.append('size', size);
  if (tracking) {
    formData.append('tracking', tracking);
  }
  if (trackingImage) {
    const trackingImageFile = await fetch(trackingImage).then(res => res.blob());
    formData.append('tracking_img', trackingImageFile, `tracking.png`);
  }
  if (carrierId) {
    formData.append('carrier_id', carrierId);
  }
  return dispatch({
    [RSAA]: {
      endpoint: `${API_ROOT}/deliveries`,
      method: 'POST',
      headers: state => {
        const header = getAuthorizationHeader(state);
        delete header['Content-Type'];
        return header;
      },
      types: types.POST_DELIVERY_REQUEST_RSAA,
      fetch: IS_MOCK ? mock.deliver : fetch,
      body: formData,
    },
  });
};

export const retryDelivery = ({ transactionId, reason = 'box_size', codeUsed = false }) => ({
  [RSAA]: {
    endpoint: `${API_ROOT}/deliveries/${transactionId}/retries`,
    method: 'POST',
    headers: getAuthorizationHeader,
    types: types.RETRY_DELIVERY_REQUEST_RSAA,
    fetch: IS_MOCK ? mock.retryDelivery : fetch,
    body: JSON.stringify({
      code_used: codeUsed,
      reason,
    }),
  },
});

export const deleteDelivery = ({ transactionId }) => ({
  [RSAA]: {
    endpoint: `${API_ROOT}/deliveries/${transactionId}`,
    method: 'DELETE',
    headers: getAuthorizationHeader,
    types: types.DELETE_DELIVERY_REQUEST_RSAA,
    fetch: IS_MOCK ? mock.deleteDelivery : fetch,
  },
});
