import * as types from '../actions/types';

const INITIAL_APP_STATE = {
  isNewDeliveryProcess: false,
  offline: false,
  errorMessage: '',
  appMessage: '',
  activeScanning: '',
  cookiesLoaded: false,
  header: {},
  router: {},
  lang: 'es',
};

export const app = (state = INITIAL_APP_STATE, action) => {
  switch (action.type) {
    case types.ROUTER_UPDATE:
      return {
        ...state,
        router: {
          ...action.payload,
        },
        isNewDeliveryProcess: false,
      };
    case types.ACTIVE_SCANNING:
    case types.LAYOUT_UPDATE:
      return {
        ...state,
        ...action.payload,
      };
    case types.OFFLINE_CHANGE:
      return {
        ...state,
        offline: action.offline,
      };
    case types.APP_MESSAGE_SET:
      return {
        ...state,
        appMessage: action.message,
      };
    case types.APP_MESSAGE_RESET:
      return {
        ...state,
        appMessage: '',
      };
    case types.ERROR_MESSAGE_SET:
      return {
        ...state,
        errorMessage: action.errorMessage,
      };
    case types.ERROR_MESSAGE_RESET:
      return {
        ...state,
        errorMessage: '',
      };
    case types.SET_NEW_DELIVERY_PROCESS:
      return {
        ...state,
        isNewDeliveryProcess: true,
      };
    case types.COOKIES_LOADED:
      return {
        ...state,
        cookiesLoaded: true,
      };
    case types.SET_LANG:
      return {
        ...state,
        lang: action.lang,
      };
    default:
      return {
        ...state,
      };
  }
};
