export const removeKeys = (obj, keysToOmit) => {
  const newObj = {};

  Object.keys(obj).forEach(key => {
    if (keysToOmit.indexOf(key) === -1) {
      newObj[key] = obj[key];
    }
  });

  return newObj;
};
