/* eslint-disable no-console */
/* eslint-disable no-unused-vars */

import {
  FETCH_TIMEOUT,
  responseSuccess,
  responseError,
  getParamsFromURL,
  getParamsFromBody,
} from './utils';
import { getCarriersObject, couriers, allowedPrefixes } from './data';

const statusGuest = responseSuccess({ status: 'guest' });
const statusRegisteredCourier = responseSuccess({ status: 'courier' });
const statusRegistered = responseSuccess({ status: 'registered' });
const statusUnregisteredUser = responseError({ code: 'unregistered_user' });
const statusInvalidPhone = responseError({ code: 'invalid_phone' });
const statusInvalidType = responseError({ code: 'invalid_user_type' }, 403);
const statusInvalidData = responseError({ code: 'invalid_data' });
const statusUserBlocked = responseSuccess({ status: 'guest', code: 'user_blocked' });
const statusTryAgain = responseError({ code: 'something_went_wrong_try_again' });
const statusInvalidDataPrefix = responseError({
  code: 'invalid_data',
  detail: { phone: 'Missing prefix' },
});
const statusInvalidDataPrefixNotAllowed = responseError({
  code: 'invalid_data',
  detail: { phone: "The phone number's country is not supported" },
});

const prefixRegEx = /^\+(?:[0-9]{1,3})/;
const hasAllowedPrefix = phone => allowedPrefixes.some(prefix => phone.includes(prefix));
const getCourierByPhone = phone => couriers.find(courier => phone.includes(courier.id)) || {};

const carriers = getCarriersObject();

const status = (resource, init) => {
  const queryParams = getParamsFromURL(resource);
  const phoneNumber = decodeURIComponent(queryParams.phone);
  let statusResponse;

  if (!prefixRegEx.test(phoneNumber)) {
    statusResponse = statusInvalidDataPrefix;
  } else if (hasAllowedPrefix(phoneNumber)) {
    const courier = getCourierByPhone(phoneNumber);

    switch (courier.status) {
      case 'guest':
        statusResponse = statusGuest;
        break;
      case 'courier':
        statusResponse = statusRegisteredCourier;
        break;
      case 'registered':
        statusResponse = statusRegistered;
        break;
      case 'invalidPhone':
        statusResponse = statusInvalidPhone;
        break;
      case 'invalidType':
        statusResponse = statusInvalidType;
        break;
      case 'unregistered':
        statusResponse = statusUnregisteredUser;
        break;
      case 'invalidData':
        statusResponse = statusInvalidData;
        break;
      case 'userBlocked':
        statusResponse = statusUserBlocked;
        break;
      default:
        statusResponse = statusTryAgain;
        break;
    }
  } else {
    statusResponse = statusInvalidDataPrefixNotAllowed;
  }

  const response = statusResponse();
  console.log('MOCK', resource, init, response);
  return new Promise(resolve => setTimeout(() => resolve(response), FETCH_TIMEOUT));
};

const createGuestSuccess = responseSuccess('', 201);
let carrierId = 66;

/* eslint-disable camelcase */
const createGuest = (resource, init) => {
  const { carrier_id } = getParamsFromBody(init);
  carrierId = carrier_id;
  const response = createGuestSuccess();

  console.log('MOCK', resource, init, response);
  return new Promise(resolve => setTimeout(() => resolve(response), FETCH_TIMEOUT));
};

const loginUserOK = phone =>
  responseSuccess(
    {
      access_token: phone,
      expires_in: 30 * 24 * 60 * 60 * 1000,
      token_type: 'Bearer',
      scope: '',
      refresh_token: '',
    },
    200,
  );
const loginUserError = responseError({ code: 'invalid_credentials' }, 400);
const loginUserBlocked = responseError({ code: 'user_blocked' }, 403);
const loginServerError = responseError({}, 500);

const loginUser = (resource, init) => {
  const { username, password } = getParamsFromBody(init);
  const courier = getCourierByPhone(username);
  let loginResponse = loginUserOK(username, password);

  if (courier.status === 'userBlocked') {
    loginResponse = loginUserBlocked;
  } else if (password && password !== '1234') {
    if (password === '0000') {
      loginResponse = loginServerError;
    } else {
      loginResponse = loginUserError;
    }
  }

  const response = loginResponse();
  console.log('MOCK', resource, init, response);
  return new Promise(resolve => setTimeout(() => resolve(response), FETCH_TIMEOUT));
};

const profileSuccess = phone =>
  responseSuccess(
    {
      id: phone.substring(3, 7),
      full_phone_number: phone,
      role: 24,
      carrier: carriers[carrierId],
    },
    200,
  );
const guestProfile = (resource, init) => {
  const phone = init.headers.Authorization.split(' ')[1];
  const response = profileSuccess(phone)();

  console.log('MOCK', resource, init, response);
  return new Promise(resolve => setTimeout(() => resolve(response), FETCH_TIMEOUT));
};

const userProfileOK = phone => {
  const { role } = getCourierByPhone(phone);

  const user = {
    id: phone.substring(3, 7),
    full_phone_number: phone,
    role,
  };

  if (role === 19) {
    user.location = 507;
  }

  return responseSuccess(user, 200);
};

const userProfile = (resource, init) => {
  const phone = init.headers.Authorization.split(' ')[1];
  const response = userProfileOK(phone)();

  console.log('MOCK', resource, init, response);
  return new Promise(resolve => setTimeout(() => resolve(response), FETCH_TIMEOUT));
};

export { status, createGuest, loginUser, guestProfile, userProfile };
