export const SET_TOKEN = 'SET_TOKEN';
export const COURIER_RESET = 'COURIER_RESET';
export const COURIER_SET_CARRIER = 'COURIER_SET_CARRIER';
export const COURIER_SET_PHONE = 'COURIER_SET_PHONE';
export const COURIER_SET_ALPHA2CODE = 'COURIER_SET_ALPHA2CODE';
export const COURIER_SET_TOS = 'COURIER_SET_TOS';
export const COURIER_SET_PIN = 'COURIER_SET_PIN';
export const COURIER_SET_STATUS = 'COURIER_SET_STATUS';
export const COURIER_REQUIRE_VALIDATION = 'COURIER_REQUIRE_VALIDATION';
export const CARRIERS_CREATE = 'CARRIERS_CREATE';
export const CARRIERS_QUERY = 'CARRIERS_QUERY';
