import { store } from '../store';
import {
  getDeliveryLocationAddress,
  getTracking,
  getTrackingImage,
  getDeliveryRecipient,
  getDeliveryLocationId,
  deliveryIdSelector,
  getToken,
  getPhone,
  getTos,
  getCarrierName,
  getRoleId,
} from '../selectors';

const TOTAL_STEPS = 5;

const throwImportError = error => {
  throw new Error(error);
};

const deliveryFromTitle = state => {
  const carrierName = getCarrierName(state);
  let label = 'delivery';
  if (carrierName) {
    label = 'delivery_from';
    return { label, values: { carrierName } };
  }
  return { label };
};

const deliveryToTitle = state => {
  const { consumerName, consumerPhone } = getDeliveryRecipient(state);
  let label = 'delivery';
  if (consumerName || consumerPhone) {
    label = 'delivery_to';
    return { label, values: { consumer: consumerName || consumerPhone } };
  }
  return { label };
};

const addressTitle = state => {
  const address = getDeliveryLocationAddress(state);

  return { label: 'delivery_address', values: { address } };
};

export const routes = [
  {
    name: 'not-found',
    pattern: '*',
  },
  {
    name: 'home',
    pattern: '/',
    component: 'page-home',
    callback(route) {
      return this.name === route
        ? import('../pages/home').catch(throwImportError)
        : Promise.resolve();
    },
    attributes: {
      flavour: 'darkGrey',
    },
    data: {
      flavour: 'darkGrey',
      header: {
        logo: true,
      },
      hasFooter: true,
      flow: {
        next: '/courier',
        location: '/location',
        carrier: '/courier/carrier',
      },
    },
  },
  {
    name: 'courier',
    pattern: '/courier',
    component: 'page-courier',
    callback(route) {
      return this.name === route
        ? import('../pages/courier').catch(throwImportError)
        : Promise.resolve();
    },
    data: {
      flavour: 'lightGrey',
      header: {
        title: { label: 'new_delivery' },
        navButton: 'back',
      },
      flow: {
        guest: '/location',
        regularDelivery: '/regular-delivery',
        sms: '/courier/sms',
        password: '/courier/password',
      },
      progress: {
        steps: TOTAL_STEPS,
        active: 0,
      },
    },
  },
  {
    name: 'courier-sms',
    pattern: '/courier/sms',
    component: 'page-courier',
    callback(route) {
      return this.name === route
        ? import('../pages/courier').catch(throwImportError)
        : Promise.resolve();
    },
    guard() {
      const state = store.getState();
      return !!(getPhone(state) && getTos(state));
    },
    attributes: {
      validation: 'sms',
    },
    data: {
      flavour: 'lightGrey',
      header: {
        title: { label: 'new_delivery' },
        navButton: 'back',
      },
      flow: {
        next: '/courier/country',
      },
      progress: {
        steps: TOTAL_STEPS,
        active: 0,
      },
    },
  },
  {
    name: 'courier-password',
    pattern: '/courier/password',
    component: 'page-courier',
    callback(route) {
      return this.name === route
        ? import('../pages/courier').catch(throwImportError)
        : Promise.resolve();
    },
    guard() {
      const state = store.getState();
      return !!(getPhone(state) && getTos(state));
    },
    attributes: {
      validation: 'password',
    },
    data: {
      flavour: 'lightGrey',
      header: {
        title: { label: 'new_delivery' },
        navButton: 'back',
      },
      flow: {
        next: '/courier/country',
      },
      progress: {
        steps: TOTAL_STEPS,
        active: 0,
      },
    },
  },
  {
    name: 'courier-country',
    pattern: '/courier/country',
    component: 'page-courier',
    callback(route) {
      return this.name === route
        ? import('../pages/courier').catch(throwImportError)
        : Promise.resolve();
    },
    guard() {
      const state = store.getState();
      return !!(getPhone(state) && getTos(state));
    },
    attributes: {
      validation: 'country',
    },
    data: {
      flavour: 'lightGrey',
      header: {
        title: { label: 'new_delivery' },
        navButton: 'back',
      },
      flow: {
        next: '/courier/carrier',
      },
      progress: {
        steps: TOTAL_STEPS,
        active: 0,
      },
    },
  },
  {
    name: 'carrier',
    pattern: '/courier/carrier',
    component: 'page-carrier',
    callback(route) {
      return this.name === route
        ? import('../pages/carrier').catch(throwImportError)
        : Promise.resolve();
    },
    guard: () => {
      const state = store.getState();
      const role = getRoleId(state);

      if (role === 24) {
        return !!(getPhone(state) && getTos(state) && !getToken(state));
      }

      return !!(getPhone(state) && getTos(state));
    },
    data: {
      flavour: 'lightGrey',
      header: {
        title: { label: 'new_delivery' },
        navButton: 'back',
      },
      flow: {
        notAuthorized: '/courier',
        next: '/location',
        tracking: '/tracking',
        full: '/full',
        regularDelivery: '/regular-delivery',
        search: '/courier/carrier/search',
      },
      progress: {
        steps: TOTAL_STEPS,
        active: 0,
      },
    },
  },
  {
    name: 'carrier-search',
    pattern: '/courier/carrier/search',
    component: 'page-carrier',
    callback(route) {
      return this.name === route
        ? import('../pages/carrier').catch(throwImportError)
        : Promise.resolve();
    },
    guard: () => {
      const state = store.getState();
      return !!(getPhone(state) && getTos(state));
    },
    attributes: {
      search: true,
    },
    data: {
      flavour: 'lightGrey',
      header: {
        title: { label: 'new_delivery' },
        navButton: 'close',
      },
      flow: {
        notAuthorized: '/courier',
        next: '/location',
        tracking: '/tracking',
        full: '/full',
        regularDelivery: '/regular-delivery',
      },
      progress: {
        steps: TOTAL_STEPS,
        active: 0,
      },
    },
  },
  {
    name: 'location',
    pattern: '/location',
    component: 'page-location',
    callback(route) {
      return this.name === route
        ? import('../pages/location').catch(throwImportError)
        : Promise.resolve();
    },
    guard: () => {
      const state = store.getState();
      return !!getToken(state);
    },
    data: {
      flavour: 'lightGrey',
      header: {
        title: deliveryFromTitle,
        navButton: 'back',
        cta: 'help',
      },
      flow: {
        next: '/tracking',
        noCamera: '/no-camera',
        full: '/full',
        registered: '/registered',
        regularDelivery: '/regular-delivery',
      },
      progress: {
        steps: TOTAL_STEPS,
        active: 1,
      },
    },
  },
  {
    name: 'tracking',
    pattern: '/tracking',
    component: 'page-tracking',
    callback(route) {
      return this.name === route
        ? import('../pages/tracking').catch(throwImportError)
        : Promise.resolve();
    },
    guard: () => {
      const state = store.getState();
      return !!(getToken(state) && getDeliveryLocationId(state));
    },
    data: {
      flavour: 'lightGrey',
      header: {
        title: addressTitle,
        navButton: 'back',
        cta: 'help',
      },
      flow: {
        next: '/consumer',
        noCamera: '/no-camera',
      },
      progress: {
        steps: TOTAL_STEPS,
        active: 2,
      },
    },
  },
  {
    name: 'consumer',
    pattern: '/consumer',
    component: 'page-consumer',
    callback(route) {
      return this.name === route
        ? import('../pages/consumer').catch(throwImportError)
        : Promise.resolve();
    },
    guard: () => {
      const state = store.getState();
      return !!(
        getToken(state) &&
        getDeliveryLocationId(state) &&
        (getTracking(state) || getTrackingImage(state))
      );
    },
    attributes: {
      flavour: 'lightGrey',
    },
    data: {
      flavour: 'lightGrey',
      header: {
        title: addressTitle,
        navButton: 'back',
        cta: 'add-user',
      },
      flow: {
        next: '/code',
        new: '/consumer/new',
        regularDelivery: '/regular-delivery',
        noProvisionalUsers: '/regular-delivery?reason=no-provisional',
        full: '/full',
      },
      progress: {
        steps: TOTAL_STEPS,
        active: 3,
      },
    },
  },
  {
    name: 'consumer-new',
    pattern: '/consumer/new',
    component: 'page-new-consumer',
    callback(route) {
      return this.name === route
        ? import('../pages/new-consumer').catch(throwImportError)
        : Promise.resolve();
    },
    guard: () => {
      const state = store.getState();
      return (
        !!getToken(state) &&
        getDeliveryLocationId(state) &&
        (getTracking(state) || getTrackingImage(state))
      );
    },
    attributes: {
      flavour: 'lightGrey',
    },
    data: {
      flavour: 'lightGrey',
      header: {
        title: addressTitle,
        navButton: 'back',
      },
      flow: {
        next: '/code',
        regularDelivery: '/regular-delivery',
        noProvisionalUsers: '/regular-delivery?reason=no-provisional',
        full: '/full',
      },
      progress: {
        steps: TOTAL_STEPS,
        active: 3,
      },
    },
  },
  {
    name: 'code',
    pattern: '/code',
    component: 'page-code',
    callback(route) {
      return this.name === route
        ? import('../pages/code').catch(throwImportError)
        : Promise.resolve();
    },
    guard: () => {
      const state = store.getState();
      return !!(getToken(state) && deliveryIdSelector(state));
    },
    data: {
      flavour: 'darkGrey',
      header: {
        title: deliveryToTitle,
        navButton: 'back',
        cta: 'delete',
      },
      flow: {
        cancelDelivery: '/cancelled',
        regularDelivery: '/regular-delivery',
        full: '/full',
        carrier: '/courier/carrier',
        success: '/success',
        next: '/code',
      },
      progress: {
        steps: TOTAL_STEPS,
        active: 4,
      },
    },
  },
  {
    name: 'success',
    pattern: '/success',
    component: 'page-success',
    callback(route) {
      return this.name === route
        ? import('../pages/success').catch(throwImportError)
        : Promise.resolve();
    },
    guard() {
      const state = store.getState();
      return !!(getToken(state) && getDeliveryLocationAddress(state));
    },
    attributes: {
      'full-height': true,
    },
    data: {
      flavour: 'darkGrey',
      flow: {
        same: '/tracking',
        next: '/location',
      },
    },
  },
  {
    name: 'cancelled',
    pattern: '/cancelled',
    component: 'page-cancelled',
    callback(route) {
      return this.name === route
        ? import('../pages/cancelled').catch(throwImportError)
        : Promise.resolve();
    },
    data: {
      flavour: 'darkGrey',
      header: {
        title: deliveryFromTitle,
      },
      flow: {
        next: '/courier',
        location: '/location',
        carrier: '/courier/carrier',
      },
    },
  },
  {
    name: 'regular-delivery',
    pattern: '/regular-delivery',
    component: 'page-regular-delivery',
    callback(route) {
      return this.name === route
        ? import('../pages/regular-delivery').catch(throwImportError)
        : Promise.resolve();
    },
    data: {
      flavour: 'darkGrey',
      header: {
        title: deliveryFromTitle,
        navButton: 'close',
        cta: 'help',
      },
      flow: {
        home: '/home',
      },
    },
  },
  {
    name: 'full',
    pattern: '/full',
    component: 'page-full',
    callback(route) {
      return this.name === route
        ? import('../pages/full').catch(throwImportError)
        : Promise.resolve();
    },
    data: {
      flavour: 'darkGrey',
      header: {
        title: deliveryFromTitle,
        navButton: 'close',
        cta: 'help',
      },
      flow: {
        home: '/home',
      },
    },
  },
  {
    name: 'no-camera',
    pattern: '/no-camera',
    component: 'page-no-camera',
    callback(route) {
      return this.name === route
        ? import('../pages/no-camera').catch(throwImportError)
        : Promise.resolve();
    },
    data: {
      flavour: 'darkGrey',
      header: {
        title: deliveryFromTitle,
        navButton: 'close',
        cta: 'help',
      },
      flow: {
        home: '/home',
      },
    },
  },
  {
    name: 'cookies',
    pattern: '/cookies',
    component: 'page-cookies',
    callback(route) {
      return this.name === route
        ? import('../pages/cookies').catch(throwImportError)
        : Promise.resolve();
    },
    attributes: {
      flavour: 'darkGrey',
    },
    data: {
      flavour: 'darkGrey',
      header: {
        navButton: 'back',
        cta: 'help',
      },
      hasFooter: true,
    },
  },
];
