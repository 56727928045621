import * as types from '../actions/types';

const INITIAL_DELIVERY_STATE = {};

export const delivery = (state = INITIAL_DELIVERY_STATE, action) => {
  switch (action.type) {
    case types.DELIVERY_SET_ID:
      return {
        ...state,
        id: action.payload.deliveryId,
      };
    case types.DELIVERY_SET_LOCK_ID:
    case types.DELIVERY_SET_LOCATION_ID:
    case types.DELIVERY_SET_TRACKING:
    case types.DELIVERY_SET_TRACKING_IMAGE:
    case types.DELIVERY_SET_RECIPIENT:
    case types.DELIVERY_SET_CONSUMER_NAME:
    case types.DELIVERY_SET_CONSUMER_PHONE_NUMBER:
      return {
        ...state,
        ...action.payload,
      };
    case types.DELIVERY_RESET_WITH_LOCATION:
      return {
        locationId: action.payload.locationId,
      };
    case types.DELIVERY_RESET:
      return INITIAL_DELIVERY_STATE;
    default:
      return {
        ...state,
      };
  }
};
