import * as api from '../api';
import * as types from '../types';
import { nextStep, startLoading, stopLoading } from './flow';
import { setDeliveryId, setRecipient, setConsumerName, setConsumerPhone } from '../delivery';
import { setAppError } from '../app';
import {
  getDelivery,
  getDeliveryLocationId,
  getDeliveryLocationBoxesInfo,
  getSelectedCarrierId,
  getStatus,
} from '../../selectors';

const STATUS_REGISTERED = 'registered';

export const consumerWithoutPhone = () => dispatch => {
  dispatch({
    type: types.CONSUMER_WITHOUT_PHONE,
  });
  dispatch(nextStep('regularDelivery'));
};

export const provisionalUserNotAllowed = () => dispatch => {
  dispatch({
    type: types.PROVISIONAL_CONSUMER_NOT_ALLOWED,
  });
  dispatch(nextStep('noProvisionalUsers'));
};

export const fetchLocationUsersList = query => async (dispatch, getState) => {
  const state = getState();
  const locationId = getDeliveryLocationId(state);
  const loadingProcess = 'LOCATION_USERS_REQUEST_RSAA';
  await dispatch(
    startLoading({
      action: loadingProcess,
    }),
  );
  try {
    const apiResponse = await dispatch(api.fetchLocationUsers({ locationId, query }));
    if (apiResponse.error) {
      throw new Error(apiResponse.payload);
    }
  } catch (err) {
    dispatch(setAppError(err));
  }
  return dispatch(stopLoading(loadingProcess));
};

const deliver = body => async dispatch => {
  try {
    const apiResponse = await dispatch(api.postDelivery(body));
    if (apiResponse.error) {
      const { payload: { response: { code } = {} } = {} } = apiResponse;
      switch (code) {
        case 'invalid_data':
          throw new Error(apiResponse.payload);
        case 'box_not_available':
          dispatch(nextStep('full'));
          break;
        case 'user_autocreation_forbidden':
          dispatch(nextStep('noProvisionalUsers'));
          break;
        case 'user_blocked':
          dispatch(nextStep('noProvisionalUsers'));
          break;
        default:
          dispatch(nextStep('regularDelivery'));
          break;
      }
    } else {
      await dispatch(setConsumerName({ consumerName: '' }));
      await dispatch(setConsumerPhone({ consumerPhone: '' }));
      const deliveryId = Object.keys(apiResponse.payload.entities.deliveries)[0];
      const { type } = apiResponse.payload.entities.deliveries[deliveryId];
      await dispatch(setDeliveryId({ deliveryId }));
      if (type === 'box') {
        dispatch(nextStep('next'));
      } else {
        dispatch(nextStep('regularDelivery'));
      }
    }
  } catch (err) {
    dispatch(setAppError(err));
  }
};

export const confirmConsumer = recipient => async (dispatch, getState) => {
  const loadingProcess = 'FLOW_CONSUMER_NEXT';
  dispatch(
    startLoading({
      action: loadingProcess,
    }),
  );
  await dispatch(setRecipient({ recipient }));
  const state = getState();
  const { tracking, locationId, trackingImage } = getDelivery(state);
  const [{ size }] = getDeliveryLocationBoxesInfo(state);
  const carrierId = getSelectedCarrierId(state);
  const status = getStatus(state);
  await dispatch(
    deliver({
      locationId,
      consumerPhone: recipient.consumerPhone,
      consumerName: recipient.consumerName,
      tracking,
      trackingImage,
      size,
      ...(status === STATUS_REGISTERED && { carrierId }),
    }),
  );
  dispatch(stopLoading(loadingProcess));
};
