import merge from 'lodash-es/merge';

const INIT_ENTITIES = {
  users: {},
  carriers: {},
  locations: {},
  deliveries: {},
};

export const entities = (state = INIT_ENTITIES, action) => {
  if (action.payload && action.payload.entities) {
    return merge({}, state, action.payload.entities);
  }
  return state;
};
