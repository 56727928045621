import * as api from '../api';
import * as types from '../types';
import { nextStep, loginAndProfile, startLoading, stopLoading } from './flow';
import { lockIdAuto } from './location';
import { setCarrier } from '../courier';
import { setAppError } from '../app';
import {
  getPhone,
  getAlpha2Code,
  getPin,
  getTos,
  getRoleId,
  getSelectedCarrierId,
  getLocationId,
} from '../../selectors';
import { resetDelivery, resetDeliveryWithLocation } from '../delivery';

const ROLE_CONCIERGE = 19;
const ROLE_MANAGER = 29;

export const fetchCarriersList = () => async (dispatch, getState) => {
  const loadingProcess = 'FLOW_CARRIER_FETCH_NEXT';
  const alpha2Code = getAlpha2Code(getState());

  dispatch(
    startLoading({
      action: loadingProcess,
    }),
  );
  await dispatch(api.fetchCarriers(alpha2Code));
  return dispatch(stopLoading(loadingProcess));
};

const getCreateGuestData = state => ({
  phone: getPhone(state),
  introduced_pin: getPin(state),
  tos: getTos(state),
  carrier_id: getSelectedCarrierId(state),
});

const createGuest = () => async (dispatch, getState) => {
  const apiResponse = await dispatch(api.createGuest(getCreateGuestData(getState())));
  if (apiResponse.error) {
    throw new Error(apiResponse.error);
  }
  await dispatch(loginAndProfile());
  await dispatch(nextStep('next'));
};

const startDelivery = () => async (dispatch, getState) => {
  const role = await getRoleId(getState());
  const locationId = getLocationId(getState());

  switch (role) {
    case ROLE_CONCIERGE:
      await dispatch(resetDeliveryWithLocation(locationId));
      await dispatch(lockIdAuto());
      break;
    case ROLE_MANAGER:
      await dispatch(resetDelivery());
      await dispatch(nextStep('next'));
      break;
    default:
      await dispatch(createGuest());
      break;
  }
};

export const confirmCarrier = () => async dispatch => {
  const loadingProcess = 'FLOW_CARRIER_NEXT';
  dispatch(
    startLoading({
      action: loadingProcess,
    }),
  );
  try {
    await dispatch(startDelivery());
  } catch (err) {
    dispatch(setAppError(err));
  }
  return dispatch(stopLoading(loadingProcess));
};

export const searchCarrier = () => dispatch => {
  dispatch({
    type: types.SEARCH_CARRIER,
  });
  dispatch(nextStep('search'));
};

export const confirmNewCarrier = name => async (dispatch, getState) => {
  const country = getAlpha2Code(getState());
  const loadingProcess = 'FLOW_CARRIER_NEXT';

  dispatch({
    type: types.CREATE_CARRIER,
  });
  dispatch(
    startLoading({
      action: loadingProcess,
    }),
  );
  try {
    const apiResponse = await dispatch(api.postCarrier({ name, country }));
    if (apiResponse.error) {
      throw new Error(apiResponse.payload);
    }
    await dispatch(setCarrier({ id: apiResponse.payload.result }));
    await dispatch(startDelivery());
  } catch (err) {
    dispatch(setAppError(err));
  }
  return dispatch(stopLoading(loadingProcess));
};
