import { createSelector } from 'reselect';

const uiSelector = state => state.ui;

export const isLoading = createSelector(uiSelector, ui => ui.isLoading);
export const isScanSuccessful = createSelector(uiSelector, ui => ui.isScanSuccessful);
export const isScanInvalid = createSelector(uiSelector, ui => ui.isScanInvalid);
export const isLoadingSms = createSelector(uiSelector, ui => ui.isLoadingSms);
export const isAutofixAcceptLoading = createSelector(uiSelector, ui => ui.autofixAcceptLoading);
export const isAutofixPrimaryLoading = createSelector(uiSelector, ui => ui.autofixPrimaryLoading);
export const isAutofixSecondaryLoading = createSelector(
  uiSelector,
  ui => ui.autofixSecondaryLoading,
);
export const isAutofixVisible = createSelector(uiSelector, ui => ui.autofixVisible);
export const isSmsSent = createSelector(uiSelector, ui => ui.isSmsSent);
export const isCodeRequestDisabled = createSelector(uiSelector, ui => ui.isCodeRequestDisabled);
export const getHelpVisible = createSelector(uiSelector, ui => ui.helpVisible);
export const isHelpHideForever = createSelector(uiSelector, ui => ui.isHelpHideForever);
export const isCancelDeliveryVisible = createSelector(uiSelector, ui => ui.isCancelDeliveryVisible);
export const isCallSupportVisible = createSelector(uiSelector, ui => ui.isCallSupportVisible);
