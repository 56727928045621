import * as types from '../types';
import { nextStep } from './flow';
import { updateHelpVisible } from '../ui';
import { resetDelivery, resetDeliveryWithLocation } from '../delivery';
import { getDeliveryLocationId } from '../../selectors';

export const newDeliveryProcess = nextLocation => async (dispatch, getState) => {
  dispatch(updateHelpVisible(false));
  dispatch({
    type: types.CONFIRM_NEW_DELIVERY,
    payload: { nextLocation },
  });
  if (nextLocation === 'same') {
    const locationId = getDeliveryLocationId(getState());
    await dispatch(resetDeliveryWithLocation(locationId));
    await dispatch(nextStep('same'));
  } else {
    await dispatch(resetDelivery());
    await dispatch(nextStep('next'));
  }
};

export const closeSuccessModal = () => ({
  type: types.CLOSE_SUCCESS_MODAL,
});

export const makeAnotherDelivery = () => ({
  type: types.MAKE_ANOTHER_DELIVERY,
});

export const downloadApp = () => ({
  type: types.DOWNLOAD_APP,
});
