/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
import { FETCH_TIMEOUT, responseSuccess, responseError, getParamsFromURL } from './utils';

const SMSOk = responseSuccess();
/* 
Should not happen with this client:
const errorInvalidPhone = responseError('invalid_phone');
const errorInvalidData = responseError('invalid_data');
const errorCodesRequestExceeded = responseError('codes_request_exceeded');
const errorSmsNotSent = responseError('sms_not_sent');
*/

const sendSMS = (resource, init) => {
  const response = SMSOk();
  console.log('MOCK', resource, init, response);
  return new Promise(resolve => setTimeout(() => resolve(response), FETCH_TIMEOUT));
};

const verifyPhoneOK = responseSuccess({ success: true }, 202);
const verifyPhoneInvalidCode = responseError({ code: 'invalid_data' }, 400);
const verifyPhoneInvalidPhone = responseError(
  { code: 'invalid_data', detail: { phone: ['Not a valid phone number'] } },
  400,
);
const verifyPhoneError = responseError({}, 400);

const verifyPhone = (resource, init) => {
  const queryParams = getParamsFromURL(resource);
  let verifyResponse = verifyPhoneError;

  switch (queryParams.introduced_pin) {
    case '1234':
      verifyResponse = verifyPhoneOK;
      break;

    case '1111':
      verifyResponse = verifyPhoneInvalidCode;
      break;

    case '2222':
      verifyResponse = verifyPhoneInvalidCode;
      break;

    case '3333':
      verifyResponse = verifyPhoneInvalidPhone;
      break;

    default:
      break;
  }

  const response = verifyResponse();
  console.log('MOCK', resource, init, response);
  return new Promise(resolve => setTimeout(() => resolve(response), FETCH_TIMEOUT));
};

export { sendSMS, verifyPhone };
