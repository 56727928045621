import { isValidTypeDescriptor } from 'redux-api-middleware/es/validation';

export const validateFetchTypes = types => {
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected types to be an array of three elements.');
  }
  let [requestType, successType, failureType] = types;

  if (
    typeof requestType !== 'string' &&
    typeof requestType !== 'symbol' &&
    !isValidTypeDescriptor(requestType)
  ) {
    throw new Error('Invalid request type');
  }
  if (requestType && requestType.type) {
    requestType = requestType.type;
  }

  if (
    typeof successType !== 'string' &&
    typeof successType !== 'symbol' &&
    !isValidTypeDescriptor(successType)
  ) {
    throw new Error('Invalid success type');
  }
  if (successType && successType.type) {
    successType = successType.type;
  }

  if (
    typeof failureType !== 'string' &&
    typeof failureType !== 'symbol' &&
    !isValidTypeDescriptor(failureType)
  ) {
    throw new Error('Invalid failure type');
  }
  if (failureType && failureType.type) {
    failureType = failureType.type;
  }

  return [requestType, successType, failureType];
};
