import { getEventsWithMatchingKey } from './get-events-with-matching-key';

export function createAnalyticsMiddleware(eventsMap, target) {
  if (typeof eventsMap !== 'object') {
    // eslint-disable-next-line no-console
    console.warn(`Analytics are not being tracked, eventsMap is not an object.
Please create the middleware with an eventsMap param.`);
    return () => next => action => next(action);
  }

  const getEvents = action => getEventsWithMatchingKey(eventsMap, action.type);

  return ({ getState }) => next => action => {
    const prevState = getState();
    const result = next(action);
    const nextState = getState();
    const events = getEvents(action);
    target(events.map(eventDef => eventDef(action, prevState, nextState)));
    return result;
  };
}
