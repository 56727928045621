import { LitElement, html, css } from 'lit-element';
import { classMap } from 'lit-html/directives/class-map';
import { unsafeHTML } from 'lit-html/directives/unsafe-html';
import { installOfflineWatcher } from 'pwa-helpers/network.js';
import { router } from 'lit-element-router';
import 'lit-element-router/router/router-slot';
import {
  listenForLangChanged,
  registerTranslateConfig,
  use,
  extract,
  translate,
} from '@appnest/lit-translate';
import logoCitibox from '@citibox/logo';
import '@citibox/section-header';
import '@citibox/section-footer';
import '@citibox/snack-bar';
import '@citibox/headings';
import '@citibox/buttons';
import { closeIcon, backIcon, trashIcon, addUserIcon } from '@citibox/icons';
import { TechDataCollector } from '@citibox/tech-data-collector';

import { env } from '../env';
import { routes } from '../router/routes';
import { themeStyles } from '../styles/theme-styles';
import { initCookiebotBanner } from '../services/cookiebot';

const { APP_DEFAULT_LANG, APP_SUPPORTED_LANGS } = env;
const getSupportedLangs = () => APP_SUPPORTED_LANGS.split(',');
const getNavigatorLang = () => (navigator.language || navigator.userLanguage).substring(0, 2);
const isSupportedLang = langCode => getSupportedLangs().find(lang => lang === langCode);

const navigatorLang = getNavigatorLang();
const getDefaultLang = () => (isSupportedLang(navigatorLang) ? navigatorLang : APP_DEFAULT_LANG);

initCookiebotBanner(getDefaultLang());

registerTranslateConfig({
  loader: lang => fetch(`../assets/locales/${lang}.json`).then(res => res.ok && res.json()),
  interpolate: (text, values) => {
    let newText = text;
    if (newText && typeof newText === 'object') {
      newText = JSON.stringify(newText);
      Object.entries(extract(values)).forEach(([key, value]) => {
        newText = newText.replace(new RegExp(`\\[\\[${key}\\]\\]`, 'gm'), value);
      });

      return JSON.parse(newText);
    }

    Object.entries(extract(values)).forEach(([key, value]) => {
      newText = newText.replace(new RegExp(`\\[\\[${key}\\]\\]`, 'gm'), String(extract(value)));
    });

    return newText;
  },
});

export class CouriersAppView extends LitElement {
  static get styles() {
    return [
      themeStyles,
      css`
        :host {
          display: block;
          height: 100%;
          padding: 0;
          margin: 0;
        }

        router-slot {
          display: block;
        }

        section-header {
          padding: 29px 11px 22px 19px;
          height: 80px;
          display: flex;
          align-items: start;
          justify-content: space-between;
          z-index: 1;
        }

        section-header.collapsed {
          display: none;
        }

        section-header.opaque {
          --section-header-background-color: rgba(var(--primary-color), 1);
        }

        section-header svg {
          fill: var(--title-color);
        }

        snack-bar {
          z-index: 20;
        }

        em {
          font-size: 14px;
        }

        .button-back,
        .button-back:hover,
        .button-back:active {
          display: flex;
          align-items: center;
          text-decoration: none;
          outline: none;
          -webkit-tap-highlight-color: transparent;
          border: none;
        }

        button-pill svg {
          fill: rgb(var(--primary-color));
          margin-left: -10px;
          margin-top: -3px;
          max-height: none;
          max-width: none;
        }

        .header-logo {
          align-self: center;
          display: block;
          width: 87px;
          height: 21px;
          margin-left: 5px;
        }

        .header-logo svg {
          fill: rgb(var(--secondary-color));
          height: 100%;
        }

        .header-title {
          --title-font-size: 18px;
          --title-tablet-font-size: 18px;
          --title-desktop-font-size: 18px;
          align-self: center;
          height: auto;
          color: white;
          flex-grow: 1;
          text-align: center;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin: 0 auto;
          max-width: 60%;
        }

        .help-button {
          --button-min-width: 70px;
          --button-pill-background-color: rgba(var(--primary-color), 1);
          --button-pill-text-color: rgba(var(--secondary-color), 1);
        }

        .delete-button {
          width: 28px;
          height: 28px;
          --button-pill-vertical-padding: 0;
          --button-pill-horizontal-padding: 0;
          --button-min-width: 28px;
          --button-pill-background-color: transparent;
        }

        .add-user-button {
          --button-pill-vertical-padding: 0;
          --button-pill-horizontal-padding: 0;
          --button-min-width: 28px;
          --button-pill-background-color: transparent;
        }
      `,
    ];
  }

  static get properties() {
    return {
      code: { type: String },
      header: { type: Object },
      flavour: { reflect: true },
      hasFooter: {
        type: Boolean,
        attribute: 'has-footer',
        reflect: true,
      },
      route: { type: String },
      _appMessage: { type: String },
      _cookiesLoaded: { type: Boolean },
      isRehydrated: { type: Boolean },
      _lang: { type: String },
    };
  }

  static get langs() {
    return getSupportedLangs();
  }

  constructor() {
    super();
    this.header = {};
    this.progress = {};
    this._appMessage = '';
    this.ready = true;
    this.hasLoadedStrings = false;
  }

  async connectedCallback() {
    this.unlistenForLangChanged = listenForLangChanged(i18n => {
      document.documentElement.setAttribute('lang', i18n.lang);
      this.dispatchEvent(new CustomEvent('lang-changed', { detail: i18n.lang }));
    });

    window.addEventListener('CookiebotOnAccept', () => {
      this.dispatchEvent(new CustomEvent('cookiebot-accept'));
    });

    window.addEventListener('CookiebotOnDialogDisplay', () => {
      // temporary workaround
      document.getElementById('cookie-policy-link').href = '/cookies';
    });

    window.addEventListener('popstate', () => {
      this.dispatchEvent(new CustomEvent('history-changed'));
    });

    await this.loadLang();
    super.connectedCallback();
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    this.unlistenForLangChanged();
  }

  shouldUpdate(changedProperties) {
    return this.hasLoadedStrings && super.shouldUpdate(changedProperties);
  }

  firstUpdated() {
    installOfflineWatcher(offline =>
      this.dispatchEvent(new CustomEvent('update-offline', { detail: offline })),
    );
    router(routes, (route, params, query, data) => {
      if (route === 'not-found' || route === 'not-authorized') {
        this.dispatchEvent(new CustomEvent('invalid-route', { detail: { route, data } }));
      } else {
        this.dispatchEvent(
          new CustomEvent('update-route', { detail: { route, params, query, data } }),
        );
      }
    });
    TechDataCollector();
  }

  async loadLang(langCode = getDefaultLang()) {
    await use(langCode);
    this.hasLoadedStrings = true;
  }

  render() {
    return this.isRehydrated
      ? html`
          ${this.renderHeader()} ${this.renderSnackBar()} ${this.renderPages()}
          ${this.renderFooter()}
        `
      : html``;
  }

  renderHeader() {
    const classes = {
      opaque: this.route === 'home' || this.route === 'cookies',
      collapsed: this.route === 'success',
    };
    return html`
      <section-header .progress="${this.progress}" class="${classMap(classes)}">
        ${this.renderNavButton()} ${this.renderTitle()} ${this.renderCTA()}
      </section-header>
    `;
  }

  renderSnackBar() {
    return html`
      <snack-bar ?active="${this._appMessage}" @click=${this._updateVersionClick}>
        <em>${translate(this._appMessage)}</em>
      </snack-bar>
    `;
  }

  renderFooter() {
    return this.hasFooter
      ? html`
          <section-footer
            .locales=${this.constructor.langs}
            .selected=${this._lang}
            @locale-selected=${this.localeSelectorChange}
          >
            © ${new Date().getFullYear()} Citibox
          </section-footer>
        `
      : '';
  }

  renderNavButton() {
    const { navButton } = this.header;

    if (!navButton) {
      return '';
    }

    const buttonIcon = navButton === 'close' ? closeIcon : backIcon;
    return html`
      <a href="#" @click="${this.navButtonClicked}" class="button-back">
        ${unsafeHTML(buttonIcon)}
      </a>
    `;
  }

  renderTitle() {
    const { logo, title = {} } = this.header;
    return logo
      ? html`
          <span class="header-logo">${logoCitibox}</span>
        `
      : title.label &&
          html`
            <title-m class="header-title">
              ${translate(`${title.label}`, title.values)}
            </title-m>
          `;
  }

  renderCTA() {
    const { cta } = this.header;

    if (!cta) {
      return '';
    }

    switch (cta) {
      case 'detele':
        return this.renderDeleteButton();

      case 'add-user':
        return this.renderAddUserButton();

      default:
        return this.renderHelpButton();
    }
  }

  renderHelpButton() {
    return html`
      <button-pill class="help-button" @click="${this.ctaClick}">
        ${translate('help')}
      </button-pill>
    `;
  }

  renderDeleteButton() {
    return html`
      <button-pill class="delete-button" @click="${this.ctaClick}">
        ${unsafeHTML(trashIcon)}
      </button-pill>
    `;
  }

  renderAddUserButton() {
    return html`
      <button-pill class="add-user-button" @click="${this.ctaClick}">
        ${unsafeHTML(addUserIcon)}
      </button-pill>
    `;
  }

  renderPages() {
    return html`
      <router-slot route="${this.route}">
        ${routes.map(route => this.renderPage(route))}
      </router-slot>
    `;
  }

  // eslint-disable-next-line class-methods-use-this
  renderPage(route) {
    const { name, component, attributes = {} } = route;
    if (component) {
      // eslint-disable-next-line no-param-reassign
      route.node = route.node || document.createElement(component);
      Object.keys(attributes).forEach(key => {
        if (attributes[key] === false) {
          route.node.removeAttribute(key);
        } else {
          route.node.setAttribute(key, attributes[key]);
        }
      });
      route.node.setAttribute('slot', name);
      return route.node;
    }
    return '';
  }

  navButtonClicked(event) {
    event.preventDefault();
    this.dispatchEvent(new CustomEvent('nav-button-clicked'));
  }

  ctaClick() {
    this.dispatchEvent(new CustomEvent('cta-button-clicked'));
  }

  _updateVersionClick() {
    if (this._appMessage === 'click_to_update_last_version') {
      this.dispatchEvent(new CustomEvent('update-version-clicked'));
    }
  }

  // eslint-disable-next-line class-methods-use-this
  localeSelectorChange(event) {
    this.loadLang(event.detail);
  }
}

customElements.define('couriers-app-view', CouriersAppView);
