import { env } from '../env';

export const getLatestVersion = async () => {
  try {
    const versionFile = await fetch('../../version.txt', {
      method: 'GET',
      headers: { Accept: 'text/plain' },
    });

    if (!versionFile.ok) {
      return null;
    }

    return versionFile.text().then(res => res.trim());
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(`Something wrong happened retrieving version file: ${error}`);
    return null;
  }
};

// Preference between versions is established as follows: https://semver.org/#spec-item-11
export const compareVersions = (current, latest) => {
  const currentParts = current.split('.');
  const latestParts = latest.split('.');

  for (let i = 0; i < 3; i += 1) {
    const numCurrent = Number(currentParts[i]);
    const numLatest = Number(latestParts[i]);

    if (numCurrent > numLatest) return 1;
    if (numLatest > numCurrent) return -1;
  }

  return 0;
};

export const isLatestVersion = async () => {
  const latestVersion = await getLatestVersion();
  const currentVersion = env.APP_VERSION;

  if (!latestVersion) {
    return true; // if there is no latest version (e.g. file doesn't exist, error fetching...) return true to avoid comparison
  }

  return compareVersions(currentVersion, latestVersion) !== -1;
};
